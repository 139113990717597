import { useEffect } from "react";

const loadGoogleMaps = (callback) => {
    if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,geometry,drawing&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
        script.async = true;
        script.defer = true;
        script.onload = callback;
        document.body.appendChild(script);
    } else {
        callback();
    }
};

const GoogleMapsLoader = ({ onLoad }) => {
    useEffect(() => {
        loadGoogleMaps(onLoad);
    }, [onLoad]);

    return null;
};

export default GoogleMapsLoader;
