import asyncComponent from "util/asyncComponent";

const routesOutsideApp = [
    // System Pages
    {
        path: '/login',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/user/login')),
        exact: true
    },
    {
        path: '/recover-password',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/user/reset-password')),
        exact: true
    },
    {
        path: '/app-privacy-policy',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/user/app-privacy-policy')),
        exact: true
    },
    {
        path: '/recursos_viaje_tpt/:token',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/externo/recursos_viaje/index')),
        exact: true
    },
    {
        path: '/monitoreo_externo/:token',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/monitoreo/externo')),
        exact: true
    },
    {
        path: '/change-password/:token',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/user/reset-password/change')),
        exact: true
    },
    {
        path: '/signup',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/user/signup')),
        exact: true
    },
    {
        path: '/error-404',
        component: asyncComponent(() => import('pages/errorPages/404')),
        exact: true
    },
    {
        path: '/error-405',
        component: asyncComponent(() => import('pages/errorPages/405')),
        exact: true
    }
];

const routesInsideApp = [
    {
        path: '/impresiones_guias',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/impresiones_guias/index')),
        exact: true
    },
    {
        path: '/recursos_viaje_tpt/:token',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/externo/recursos_viaje/index')),
        exact: true
    },
    {
        path: '/monitoreo_externo/:token',
        // component: loadable(() => import('pages/user/login')),
        component: asyncComponent(() => import('pages/monitoreo/externo')),
        exact: true
    },
    {
        path: '/dashboard',
        component: asyncComponent(() => import('pages/dashboard')),
        exact: true
    },
    {
        path: '/reserva/add',
        component: asyncComponent(() => import('pages/reservas/crear')),
        exact: true
    },
    {
        path: '/reserva/aprobar',
        component: asyncComponent(() => import('pages/reservas/aprobar')),
        exact: true
    },
    {
        path: '/reserva/actividades',
        component: asyncComponent(() => import('pages/reservas/actividades')),
        exact: true
    },
    {
        path: '/reservas-facturas',
        component: asyncComponent(() => import('pages/reservas/lista-facturas')),
        exact: true
    },
    {
        path: '/reservas',
        component: asyncComponent(() => import('pages/reservas/lista')),
        exact: true
    },
    {
        path: '/reservas/:id_reserva',
        component: asyncComponent(() => import('pages/despachos/despacho/verReservas')),
        exact: true
    },
    {
        path: '/reserva/guias',
        component: asyncComponent(() => import('pages/reservas/guias/lista')),
        exact: true
    },
    {
        path: '/despachos',
        component: asyncComponent(() => import('pages/despachos/lista')),
        exact: true
    },
    {
        path: '/actividad_camiones',
        component: asyncComponent(() => import('pages/despachos/actividad_camiones')),
        exact: true
    },
    {
        path: '/guias',
        component: asyncComponent(() => import('pages/despachos/listaGuias')),
        exact: true
    },
    {
        path: '/guias/:id_reservation_milestone_guide/cerrar',
        component: asyncComponent(() => import('pages/despachos/cerrar_guia')),
        exact: false
    },
    {
        path: '/despachos/:id_reserva',
        component: asyncComponent(() => import('pages/despachos/despacho')),
        exact: true
    },
    {
        path: '/transportistas/add',
        component: asyncComponent(() => import('pages/transportistas/crear')),
        exact: true
    },
    {
        path: '/transportistas/:id_transportista/nuevo-establecimiento',
        component: asyncComponent(() => import('pages/establecimientos/crear')),
        exact: false
    },
    {
        // path: '/transportistas/edit/:id_company',
        path: '/transportistas/:id_company/edit',
        component: asyncComponent(() => import('pages/transportistas/editar/index')),
        exact: false
    },
    {
        path: '/transportistas/:id_transportista',
        component: asyncComponent(() => import('pages/transportistas/transportista/index')),
        exact: false
    },
    {
        path: '/micompania/:id_transportista',
        component: asyncComponent(() => import('pages/transportistas/transportista/index')),
        exact: false
    },
    {
        path: '/transportistas',
        component: asyncComponent(() => import('pages/transportistas/lista')),
        exact: true
    },
    {
        path: '/conductores/add',
        component: asyncComponent(() => import('pages/conductores/crear/index')),
        exact: false
    },
    {
        path: '/conductores/:id_conductor/editar',
        component: asyncComponent(() => import('pages/conductores/editar/index')),
        exact: false
    },

    {
        path: '/conductores/:id_conductor',
        component: asyncComponent(() => import('pages/conductores/conductor/index')),
        exact: false
    },
    {
        path: '/conductores',
        component: asyncComponent(() => import('pages/conductores/lista')),
        exact: false
    },
    {
        path: '/camiones',
        component: asyncComponent(() => import('pages/camiones/lista')),
        exact: true
    },
    {
        path: '/camiones/add',
        component: asyncComponent(() => import('pages/camiones/crear/index')),
        exact: true
    },
    {
        path: '/camiones/:id_truck/editar',
        component: asyncComponent(() => import('pages/camiones/editar/index')),
        exact: false
    },
    {
        path: '/camiones/:id_camion',
        component: asyncComponent(() => import('pages/camiones/camion/index')),
        exact: true
    },
    {
        path: '/credenciales_propios',
        component: asyncComponent(() => import('pages/credenciales/propios')),
        exact: true
    },
    {
        path: '/credenciales_particulares',
        component: asyncComponent(() => import('pages/credenciales/particulares')),
        exact: true
    },
    // {
    //     path: '/credenciales/camion',
    //     component: asyncComponent(() => import('pages/credenciales/index')),
    //     exact: true
    // },
    {
        path: '/credenciales/camion/:id_camion',
        component: asyncComponent(() => import('pages/credenciales/camion/index')),
        exact: false
    },
    {
        path: '/credenciales/chofer/:id_chofer',
        component: asyncComponent(() => import('pages/credenciales/chofer/index')),
        exact: true
    },
    {
        path: '/establecimientos/add',
        component: asyncComponent(() => import('pages/establecimientos/crear/index')),
        exact: false
    },
    {
        path: '/establecimientos/:id_establecimiento',
        component: asyncComponent(() => import('pages/establecimientos/establecimiento')),
        exact: false
    },
    {
        path: '/establecimientos',
        component: asyncComponent(() => import('pages/establecimientos/lista')),
        exact: false
    },
    {
        path: '/tarifas',
        component: asyncComponent(() => import('pages/tarifas/lista')),
        exact: true
    },
    {
        path: '/sub-tarifas',
        component: asyncComponent(() => import('pages/tarifas/sub-tarifas')),
        exact: true
    },
    {
        path: '/tarifas/add',
        component: asyncComponent(() => import('pages/tarifas/crear/index')),
        exact: true
    },
    {
        path: '/tarifas/add_excel',
        component: asyncComponent(() => import('pages/tarifas/crear/massive')),
        exact: true
    },
    {
        path: '/tarifas/:id_tarifa',
        component: asyncComponent(() => import('pages/tarifas/tarifa/index')),
        exact: true
    },
    {
        path: '/tarifas/edit/:id_tarifa',
        component: asyncComponent(() => import('pages/tarifas/edit/index')),
        exact: true
    },
    {
        path: '/rutas',
        component: asyncComponent(() => import('pages/rutas/lista/index')),
        exact: true
    },
    {
        path: '/rutas/add',
        component: asyncComponent(() => import('pages/rutas/crear/index')),
        exact: true
    },
    {
        path: '/punto_conexion',
        component: asyncComponent(() => import('pages/punto_conexion/lista/index')),
        exact: true
    },
    {
        path: '/punto_conexion/add',
        component: asyncComponent(() => import('pages/punto_conexion/crear/index')),
        exact: true
    },
    {
        path: '/localizacion_cercana',
        component: asyncComponent(() => import('pages/localizacion_cercana/lista/index')),
        exact: true
    },
    {
        path: '/proyecciones/ventas',
        component: asyncComponent(() => import('pages/proyecciones/ventas/index')),
        exact: true
    },
    {
        path: '/localizacion_cercana/add',
        component: asyncComponent(() => import('pages/localizacion_cercana/crear/index')),
        exact: true
    },
    {
        path: '/alertas',
        component: asyncComponent(() => import('pages/alertas/index')),
        exact: true
    },
    {
        path: '/servicios',
        component: asyncComponent(() => import('pages/servicios/lista')),
        exact: true
    },
    {
        path: '/servicios/add',
        component: asyncComponent(() => import('pages/servicios/crear/index')),
        exact: true
    },
    {
        path: '/servicios/:id_servicio',
        component: asyncComponent(() => import('pages/servicios/servicio/index')),
        exact: true
    },
    {
        path: '/servicios/edit/:id_servicio',
        component: asyncComponent(() => import('pages/servicios/edit/index')),
        exact: true
    },
    {
        path: '/descuentos',
        component: asyncComponent(() => import('pages/descuentos/lista')),
        exact: true
    },
    {
        path: '/descuentos/redeem',
        component: asyncComponent(() => import('pages/descuentos/canjear/index')),
        exact: true
    },
    {
        path: '/descuentos/add',
        component: asyncComponent(() => import('pages/descuentos/crear/index')),
        exact: true
    },
    {
        path: '/descuentos/:id_descuento',
        component: asyncComponent(() => import('pages/descuentos/descuento/index')),
        exact: true
    },
    {
        path: '/descuentos/edit/:id_descuento',
        component: asyncComponent(() => import('pages/descuentos/edit/index')),
        exact: true
    },
    {
        path: '/mantenimiento_general',
        component: asyncComponent(() => import('pages/mantenimiento_general/index')),
        exact: true
    },
    {
        path: '/costos',
        component: asyncComponent(() => import('pages/costos')),
        exact: true
    },
    {
        path: '/ciudades',
        component: asyncComponent(() => import('pages/ciudades')),
        exact: true
    },
    {
        path: '/facturas',
        component: asyncComponent(() => import('pages/facturas/lista')),
        exact: true
    },
    {
        path: '/facturas/:numero_orden',
        component: asyncComponent(() => import('pages/facturas/factura/index')),
        exact: true
    },
    {
        path: '/sap',
        component: asyncComponent(() => import('pages/logs/sap/lista')),
        exact: true
    },
    {
        path: '/generadores/add',
        component: asyncComponent(() => import('pages/equipos/generador/crear/index')),
        exact: false
    },
    {
        path: '/generadores/:id_equipo/editar',
        component: asyncComponent(() => import('pages/equipos/generador/editar/index')),
        exact: false
    },
    {
        path: '/generadores/:id_equipo',
        component: asyncComponent(() => import('pages/equipos/generador/generador')),
        exact: false
    },
    {
        path: '/generadores',
        component: asyncComponent(() => import('pages/equipos/generador/lista')),
        exact: false
    },
    {
        path: '/chasis',
        component: asyncComponent(() => import('pages/equipos/chasis/lista')),
        exact: true
    },
    {
        path: '/chasis/:id_equipo/editar',
        component: asyncComponent(() => import('pages/equipos/chasis/editar/index')),
        exact: false
    },
    {
        path: '/chasis/add',
        component: asyncComponent(() => import('pages/equipos/chasis/crear/index')),
        exact: true
    },
    {
        path: '/chasis/:id_equipo',
        component: asyncComponent(() => import('pages/equipos/chasis/chasis')),
        exact: true
    },
    {
        path: '/modem',
        component: asyncComponent(() => import('pages/equipos/modem_gps/lista')),
        exact: true
    },
    {
        path: '/modem/add',
        component: asyncComponent(() => import('pages/equipos/modem_gps/crear/index')),
        exact: true
    },
    {
        path: '/modem/:id_equipo',
        component: asyncComponent(() => import('pages/equipos/modem_gps/modem')),
        exact: true
    },
    {
        path: '/modem/:id_equipo/editar',
        component: asyncComponent(() => import('pages/equipos/modem_gps/editar/index')),
        exact: false
    },
    {
        path: '/usuarios',
        component: asyncComponent(() => import('pages/user/lista')),
        exact: false
    },
    {
        path: '/monitoreo',
        // component: asyncComponent(() => import('pages/monitoreo/tabla')),
        component: asyncComponent(() => import('pages/despachos/actividad_camiones')),
        exact: true
    },
    {
        path: '/monitoreo/status_reservas',
        // component: asyncComponent(() => import('pages/monitoreo/tabla')),
        component: asyncComponent(() => import('pages/monitoreo/status_reservas')),
        exact: true
    },
    {
        path: '/monitoreo/recorrido_cabezal',
        component: asyncComponent(() => import('pages/monitoreo/recorrido_cabezal')),
        exact: true
    },
    {
        path: '/dashboard/status_reservas',
        // component: asyncComponent(() => import('pages/monitoreo/tabla')),
        component: asyncComponent(() => import('pages/monitoreo/status_reservas')),
        exact: true
    },
    {
        path: '/monitoreo/:id_reserva/reporte',
        component: asyncComponent(() => import('pages/monitoreo/reporte')),
        exact: false
    },
    {
        path: '/monitoreo/:id_reservation_milestone_guide',
        component: asyncComponent(() => import('pages/monitoreo/rastreo')),
        exact: true
    },
    {
        path: '/reportes/consulta-reservas',
        component: asyncComponent(() => import('pages/reportes/operativo/reservas/lista/index')),
        exact: true
    },
    {
        path: '/reportes/viaticos-registrar',
        component: asyncComponent(() => import('pages/reportes/operativo/viaticos/crear/index')),
        exact: true
    },
    {
        path: '/reportes/viaticos-cerrados',
        component: asyncComponent(() => import('pages/reportes/operativo/viaticos/lista/index')),
        exact: true
    },
    {
        path: '/reportes/bonos-registrar',
        component: asyncComponent(() => import('pages/reportes/operativo/bonos/crear/index')),
        exact: true
    },
    {
        path: '/reportes/bonos-cerrados',
        component: asyncComponent(() => import('pages/reportes/operativo/bonos/lista/index')),
        exact: true
    },
    {
        path: '/reportes/particulares-registrar',
        component: asyncComponent(() => import('pages/reportes/operativo/particulares/crear/index')),
        exact: true
    },
    {
        path: '/reportes/particulares-cerrados',
        component: asyncComponent(() => import('pages/reportes/operativo/particulares/lista/index')),
        exact: true
    },
    {
        path: '/reportes/asesores',
        component: asyncComponent(() => import('pages/reportes/comercial/asesores/index')),
        exact: true
    },
    {
        path: '/permisos',
        component: asyncComponent(() => import('pages/permisos')),
        exact: true
    },
    {
        path: '/configuracion',
        component: asyncComponent(() => import('pages/user/setting')),
        exact: true
    },
    {
        path: '/vacios',
        component: asyncComponent(() => import('pages/vacios/index')),
        exact: true
    },
    {
        path: '/logs_qr',
        component: asyncComponent(() => import('pages/mantenimiento_qr_logs/index')),
        exact: true
    },
    {
        path: "/comercial_reservas",
        component: asyncComponent(() => import("pages/comercial_reservas/index")),
        exact: true
    },
    {
        path: "/equipo_comercial",
        component: asyncComponent(() => import("pages/equipo_comercial/index")),
        exact: true
    },
    {
        path: "/operativos_reservas",
        component: asyncComponent(() => import("pages/operativos_reservas/index")),
        exact: true
    },
    {
        path: "/equipo_operativo",
        component: asyncComponent(() => import("pages/equipo_operativo/index")),
        exact: true
    },
    {
        path: "/reservas-transportista",
        component: asyncComponent(() => import("pages/recursos_particulares/transportistas/reservas/index")),
        exact: true
    },
    {
        path: "/reservas-conductores",
        component: asyncComponent(() => import("pages/recursos_particulares/conductores/reservas/index")),
        exact: true
    },

    /**********************************************
     *                                            *
     *                PROCUREMENT                 *
     *                                            *
     **********************************************/

    {
        path: '/procurement',
        component: asyncComponent(() => import('pages/procurement/lista')),
        exact: true
    },
    {
        path: '/procurement/conductores/:id_conductor',
        component: asyncComponent(() => import('pages/procurement/conductores/conductor')),
        exact: true
    },
    {
        path: '/procurement/camiones/:id_camion',
        component: asyncComponent(() => import('pages/procurement/camiones/camion')),
        exact: true
    },
    {
        path: '/procurement/empresas/:id_transportista',
        component: asyncComponent(() => import('pages/procurement/transportistas/transportista')),
        exact: true
    },
]

export default { routesOutsideApp, routesInsideApp };
