import axios from 'axios'

// const baseURL = 'http://localhost:5001';
// const baseURLCoordenadas = 'http://localhost:5002/';

const baseURLCoordenadas = 'https://locationservices.transpoint.com.ec/'
const baseURL = 'https://api.transpoint.com.ec';


export default class RestClientObj {
  static instanceAxios = axios.create({
    baseURL: baseURL,
  })
  static setInterceptor = callback => {
    RestClientObj.instanceAxios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const customError = new Error();
        customError.code = error.response.status;

        if (!error.response) {
          callback()
        }
        if (error.response.status === 403) {
          callback(error.response.data.message);
          customError.message = error.response.data.message;
        } else {
          if (error.response.data) {
            customError.message = error.response.data.message;

            if (error.response.data.mensaje)
              customError.message = error.response.data.mensaje;
          }
          else if (error.message) {
            customError.message = error.message;
          }
          else {
            error.message = 'Error';
          }
        }
        throw customError;
      },
    )
  }

  static getBaseURL = () => {
    return `${baseURL}/`
  }

  static setTokenToAxio = token => {
    RestClientObj.instanceAxios.defaults.headers.common.Authorization = token
  }
  static cleartokenAxio = () => {
    delete RestClientObj.instanceAxios.defaults.headers.common.Authorization
    // RestClientObj.instanceAxios.defaults.headers.common.Authorization = undefined
  }

  static setAuthorization = () => {
    // if (!RestClientObj.instanceAxios.defaults.headers.common.Authorization)
    //   RestClientObj.instanceAxios.defaults.headers.common.Authorization = localStorage.getItem(
    //     'token',
    //   )
  }

  /*-------Listing static times---*/

  static listTimes = () => {
    return RestClientObj.instanceAxios.get('/reservation/list_tiempos')
      .then(response => response ? response.data : null)
  }


  /*--------------MONITOREO--------------------*/

  /**
    * @param {object} params
    * @param {number} params.page
    * @param {number} params.page_size
    */
  static getMonitoreo = (params, token = null) => {
    if (token)
      return axios({
        method: 'post',
        headers: {
          'Authorization': token
        },
        url: `${baseURL}/services_no_logging/monitoreo/datos_reserva`,
        data: params
      }).then(resp => resp ? resp.data : null)


    return RestClientObj.instanceAxios.post('/reservation/monitoreo', params)
      .then(response => response ? response.data : null)
  }

  static getRecursoViajeTPT = ({ params, token }) => {
    return axios({
      method: 'post',
      headers: {
        'Authorization': token
      },
      url: `${baseURL}/services_no_logging/recursos_viaje/datos_recursos_viaje`,
      data: params
    }).then(resp => resp ? resp.data : null).catch(error => {
      throw Error(error.response.data.message)
    })
  }

  /******************************* Métodos de USUARIOS ***************************/
  static getPerfilMenu = () => {
    return RestClientObj.instanceAxios.get('/users/getPerfilMenu')
      .then(response => response ? response.data : null)
  }

  static recoverPassword = (username) => {
    this.cleartokenAxio()

    return RestClientObj.instanceAxios.post('/users/recover_pass', username)
  }


  static changePassword = (username) => {
    this.cleartokenAxio()

    return RestClientObj.instanceAxios.post('/users/change_password', username)
  }

  /**
   * @param {string} username
   * @param {string} password
   */
  static userLogin = (params) => {
    this.cleartokenAxio()
    return RestClientObj.instanceAxios.post('/users/login', params)
    // .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los permisos y el menú.
   */
  static getPermissions = () => {
    return RestClientObj.instanceAxios.get('/users/get_permissions')
  }

  static getPerfilUser = userData => {
    return RestClientObj.instanceAxios.post('/users/getPerfil', userData)
      .then(response => response ? response.data : null)
  }

  static getRoles = () => {
    return RestClientObj.instanceAxios.get('/users/get_roles')
      .then(response => response ? response.data : null)
      .catch(error => error.response)
  }

  static getEscalafones = () => {
    return RestClientObj.instanceAxios.get('/users/get_escalafones_user')
      .then(response => response ? response.data : null)
  }

  static getSettingNotifications = userData => {
    return RestClientObj.instanceAxios.get('/users/setting_notifications', userData)
      .then(response => response ? response.data : null)
  }

  static getSettingNotificationWSNumberByCompany = (companyId) => {
    return RestClientObj.instanceAxios.get(`/users/setting_notifications_ws_number/companyid/${companyId}`)
      .then(response => response ? response.data : null)
  }

  static deleteSettingNotificationWSNumberByCompany = (companyId) => {
    return RestClientObj.instanceAxios.get(`/users/delete_setting_notifications_ws_number/companyid/${companyId}`)
      .then(response => response ? response.data : null)
  }

  static getSettingNotificationsByUserId = (userData, userid) => {
    return RestClientObj.instanceAxios.get(`/users/setting_notifications/userid/${userid}`, userData)
      .then(response => response ? response.data : null)
  }

  static getSettingNotificationWSByCompanyId = (companyId) => {
    return RestClientObj.instanceAxios.get(`/users/setting_notifications_ws/companyid/${companyId}`)
      .then(response => response ? response.data : null)
  }

  static updateSettingNotificationWSByCompany = (params) => {
    return RestClientObj.instanceAxios.post('/users/update_setting_notifications_ws_company', params)
      .then(response => response ? response.data : null)
  }

  static updateSettingNotificationWSNumberByCompany = (params) => {
    return RestClientObj.instanceAxios.post('/users/update_setting_notifications_ws_number_company', params)
      .then(response => response ? response.data : null)
  }

  static getOptionsSubjectsNotifications = userData => {
    return RestClientObj.instanceAxios.get('/users/options_subjects_notifications', userData)
      .then(response => response ? response.data : null)
  }

  static guardarAsuntoPersonalizado = params => {
    return RestClientObj.instanceAxios.post('/users/save_options_subjects_notifications', params)
      .then(response => response ? response.data : null)
  }

  static guardarAsuntoPersonalizadoWithUserId = (params, userid) => {
    return RestClientObj.instanceAxios.post(`/users/save_options_subjects_notifications/userid/${userid}`, params)
      .then(response => response ? response.data : null)
  }

  static getAsuntoPersonalizado = () => {
    return RestClientObj.instanceAxios.get('/users/get_options_subjects_notifications')
      .then(response => response ? response.data : null)
  }

  static getAsuntoPersonalizadoWithUserId = (userid) => {
    return RestClientObj.instanceAxios.get(`/users/get_options_subjects_notifications/userid/${userid}`)
      .then(response => response ? response.data : null)
  }

  static guardarNotificacionAdicional = (params) => {
    return RestClientObj.instanceAxios.post('/users/save_notifications_extra_admin', params)
      .then(response => response ? response.data : null)
  }

  static cambiarEstadoNotificacionAdicional = (params) => {
    return RestClientObj.instanceAxios.post('/users/change_state_notifications_extra_admin', params)
      .then(response => response ? response.data : null)
  }

  static cargarNotificacionAdicional = () => {
    return RestClientObj.instanceAxios.get('/users/get_notifications_extra_admin')
      .then(response => response ? response.data : null)
  }

  static get_list_setting_notifications = userData => {
    return RestClientObj.instanceAxios.get('/users/list_setting_notifications', userData)
      .then(response => response ? response.data : null)
  }

  static get_list_setting_notificationsByUserid = (userData, userid) => {
    return RestClientObj.instanceAxios.get(`/users/list_setting_notifications/userid/${userid}`, userData)
      .then(response => response ? response.data : null)
  }

  static get_notifications_client = userData => {
    return RestClientObj.instanceAxios.get('/users/get_notifications_client', userData)
      .then(response => response ? response.data : null)
  }

  static get_notifications_clientByUserId = (userData, userid) => {
    return RestClientObj.instanceAxios.get(`/users/get_notifications_client/userid/${userid}`, userData)
      .then(response => response ? response.data : null)
  }



  static saveNotificationsClient = userData => {
    return RestClientObj.instanceAxios.post('/users/save_notification_client', userData)
      .then(response => response ? response.data : null)
  }

  static saveNotificationsClientFwd = userData => {
    return RestClientObj.instanceAxios.post('/users/save_notification_client/fwd', userData)
      .then(response => response ? response.data : null)
  }

  static updateNotificationsClient = userData => {
    return RestClientObj.instanceAxios.post('/users/update_notification_client', userData)
      .then(response => response ? response.data : null)
  }

  static updateNotificationsClientFwd = userData => {
    return RestClientObj.instanceAxios.post('/users/update_notification_client/fwd', userData)
      .then(response => response ? response.data : null)
  }

  static deleteNotificationsClient = userData => {
    return RestClientObj.instanceAxios.post('/users/delete_notification_client', userData)
      .then(response => response ? response.data : null)
  }

  static deleteNotificationsClientFwd = userData => {
    return RestClientObj.instanceAxios.post('/users/delete_notification_client/fwd', userData)
      .then(response => response ? response.data : null)
  }

  /**
   * Update the setting notification by user
   * @param {*} params
   * @param {number} params.id_notification_case
   * @param {number} params.allowed
   */
  static setSettingNotification = params => {
    return RestClientObj.instanceAxios.post('/users/update_notifications', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Update the setting notification by user with id
   * @param {*} params
   * @param {number} params.id_notification_case
   * @param {number} params.allowed
   * @param userid
   */
  static setSettingNotificationWithUserId = (params, userid) => {
    return RestClientObj.instanceAxios.post(`/users/update_notifications/userid/${userid}`, params)
      .then(response => response ? response.data : null);
  }

  /**
   * Update the email for notification by user
   * @param {*} params
   * @param {number} params.email
   * @param {number} params.restore
   */
  static setEmailNotification = params => {
    return RestClientObj.instanceAxios.post('/users/update_email_notification', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Update the email for notification by user
  * @param {*} params
  * @param {number} params.email
  * @param {number} params.restore
  * @param userid
  */
  static setEmailNotificationForUserWithId = (params, userid) => {
    return RestClientObj.instanceAxios.post(`/users/update_email_notification/userid/${userid}`, params)
      .then(response => response ? response.data : null)
  }

  /**
 * Create user only by admin local account
 * @param {string} body.identification_card | Only for field type is DRIVER
 * @param {string} body.user_mail | Only for field type is not DRIVER
 * @param {string} body.password
 * @param {string} body.name | What is account called
 * @param {string} body.type | type of account
 * @param {string} body.mail | Only for field type is DRIVER
 */
  static createUserAdminLocal = params => {
    return RestClientObj.instanceAxios.post('/users/create_user_admin_local', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Update user only by admin local account
  * @param {string} body.identification_card | Only for field type is DRIVER
  * @param {string} body.user_mail | Only for field type is not DRIVER
  * @param {string} body.password
  * @param {string} body.name | What is account called
  * @param {string} body.type | type of account
  * @param {boolean} filter.reset_password | if value is true then password'field must be update
  * @param {string} body.mail | Only for field type is DRIVER
  * @param {string} body.id_user
  * @param {string} body.id_company
  */
  static updateUserAdminLocal = params => {
    return RestClientObj.instanceAxios.post('/users/update_user_admin_local', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Update user
  * @param {string} params.identification_card | Only for field type is DRIVER
  * @param {string} params.password | Only for field type is not DRIVER
  * @param {string} params.name
  * @param {string} params.type | What is account called
  * @param {boolean} params.email | if value is true then password'field must be update
  */
  static updateMyAccount = params => {
    return RestClientObj.instanceAxios.post('/users/update_my_account', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Update user
  * @param {string} params.current_password
  * @param {boolean} params.new_password
  */
  static updateMyPasswrod = params => {
    return RestClientObj.instanceAxios.post('/users/update_my_password', params)
      .then(response => response ? response.data : null)
  }


  /**
* Delete user only by admin local account
* @param {string} body.id_user
* @param {string} body.id_company
*/
  static deleteUserAdminLocal = params => {
    return RestClientObj.instanceAxios.post('/users/delete_user_admin_local', params)
      .then(response => response ? response.data : null)
  }

  /**
   * @param {Array<string>} params.profiles | It's can be ['ADM_LOCAL', 'ADM_MASTER', 'ACCT', 'DRIVER'] - Only ADM_MASTER can filter by 'ADM_MASTER'
   * @param {string} params.name
   * @param {string} params.id_company | Only ADM_MASTER can filter by company, another profile search user of his company
   * @param {string} params.email
   * @param {string} params.username
   * @param {number} params.id_user
   * @param {number} params.current_page
   * @param {number} params.size_page
   */
  static getUsers = params => {
    return RestClientObj.instanceAxios.post('/users/get_users', params)
      .then(response => response ? response.data : null)
  }

  /**
   *Consulta de documentos de guia de transporte
   */
  static getDocumentosGuia = params => {
    return RestClientObj.instanceAxios.post('/documents_guide/get_documents_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
 *Consulta de novedades de guia de transporte
 */
  static getNovedadesGuia = params => {
    return RestClientObj.instanceAxios.post('/events_guide/get_events_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductor por ID
   * @param {number} id_driver
   */
  static getConductor = params => {
    return RestClientObj.instanceAxios.post('/driver/get_driver', params)
      .then(response => response ? response.data : null)
  }

  static asociarConductorTransportista = params => {
    return RestClientObj.instanceAxios.post('/driver/asociate_driver_company', params)
      .then(response => response ? response.data : null)
  }

  static finalizarAsociarConductorTransportista = params => {
    return RestClientObj.instanceAxios.post('/driver/close_asociate_driver_company', params)
      .then(response => response ? response.data : null)
  }

  static cambiar_estado_conductor = (params) => {
    return RestClientObj.instanceAxios.post('/driver/change_state_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Guardar observacion de conductor
   *  @param {string} observation observacion de conductor
   * @param {integer} id_driver id de conductor
 */
  static saveObservationDriver = (params) => {
    return RestClientObj.instanceAxios.post('/driver/save_observation_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
    * Eliminar observacion de conductor
    *  @param {integer} id_driver id de conductor
  */
  static deleteObservationDriver = (params) => {
    return RestClientObj.instanceAxios.post('/driver/delete_observation_driver', params)
      .then(response => response ? response.data : null)
  }

  static reporteNuevaActualizacion = (params) => {
    return RestClientObj.instanceAxios.post('/users/report_new_update_available', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Traspaso de compania de un conductor
   * @param {number} id_driver
   * @param {number} id_company
   */
  static changeCompany = id_driver => {
    return RestClientObj.instanceAxios.post('/driver/change_company', id_driver)
      .then(response => response ? response.data : null)
  }


  /**
  * Guardar nueva ruta
    * @param {*} request
    * @param {object} request.location_start
    * @param {number} request.location_start.id id del establecimiento inicial
    * @param {string} request.location_start.location_type tipo(establecimiento || punto_conexion)
    * @param {object} request.location_end
    * @param {number} request.location_end.id id del establecimiento final
    * @param {string} request.location_end.location_type tipo(establecimiento || punto_conexion)
    * @param {array<object>} request.waypoints array de paradas waypoint
    * @param {bool} request.waypoints[].stopover
    * @param {object} request.waypoints[].location
    * @param {double} request.waypoints[].location.lat
    * @param {double} request.waypoints[].location.lng
    * @param {array<object>} request.route_path array de ruta completa
    * @param {number} request.timeEstimated
    * @param {number} request.distanceEstimated
    * @param {string} request.overviewPolyline
  */
  static saveRoute = (request) => {
    return RestClientObj.instanceAxios.post('/route/add_new_route', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de choferes propios con sus credenciales y sus estados
    */
  static list_credenciales_choferes_propios = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/list_credenciales_choferes_propios', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de credenciales de un conductor asignado en una guia
  */
  static lista_credenciales_conductor_guia = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/lista_credenciales_conductor_guia', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de credenciales de un camion asignado en una guia
*/
  static lista_credenciales_camion_guia = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/lista_credenciales_camion_guia', request)
      .then(response => response ? response.data : null)
  }


  /** Actualiza estado de credencial de chofer, activo o bloqueado
*/
  static update_estado_credenciales_chofer = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/update_estado_credenciales_chofer', request)
      .then(response => response ? response.data : null)
  }


  /** Actualiza estado de credencial de camion, activo o bloqueado
*/
  static update_estado_credenciales_camion = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/update_estado_credenciales_camion', request)
      .then(response => response ? response.data : null)
  }

  /** chofer propio con sus credenciales y sus estados
  */
  static credenciales_chofer = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/credenciales_chofer', request)
      .then(response => response ? response.data : null)
  }

  /** Guardar credencial de establecimiento de chofer
 */
  static guardar_chofer_credencial_establecimiento = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/save_credencial_establecimiento_driver', request)
      .then(response => response ? response.data : null)
  }


  /** Renovar credencial de establecimiento de chofer
 */
  static renovar_chofer_credencial_establecimiento = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/renew_credencial_establecimiento_driver', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de credenciales de TPT
*/
  static lista_credenciales_tpt = () => {
    return RestClientObj.instanceAxios.get('/credenciales/list_credenciales_tpt')
      .then(response => response ? response.data : null)
  }

  /** Guardar credencial de establecimiento de camion
 */
  static guardar_camion_credencial_establecimiento = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/save_credencial_establecimiento_truck', request)
      .then(response => response ? response.data : null)
  }

  /** renovar credencial de establecimiento de camion
*/
  static renovar_camion_credencial_establecimiento = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/renew_credencial_establecimiento_truck', request)
      .then(response => response ? response.data : null)
  }

  /** Guardar credencial de tpt de camion
 */
  static guardar_camion_credencial_tpt = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/save_credencial_tpt_truck', request)
      .then(response => response ? response.data : null)
  }

  /** Renovar credencial de tpt de camion
 */
  static renovar_camion_credencial_tpt = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/renew_credencial_tpt_truck', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de choferes particulares con sus credenciales y sus estados
  */
  static list_credenciales_choferes_particulares = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/list_credenciales_choferes_particulares', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de camiones propios con sus credenciales y sus estados
  */
  static list_credenciales_camiones_propios = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/list_credenciales_camiones_propios', request)
      .then(response => response ? response.data : null)
  }

  /**
   * Datos de camion propio con sus credenciales y sus estados
  */
  static credenciales_camion = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/credenciales_camion', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de camiones particulares con sus credenciales y sus estados
  */
  static list_credenciales_camiones_particulares = (request) => {
    return RestClientObj.instanceAxios.post('/credenciales/list_credenciales_camiones_particulares', request)
      .then(response => response ? response.data : null)
  }


  /** Guardar nuevo punto de conexion
      *
      * @param {*} request
      * @param {number} request.canton // id canton
      * @param {number} request.province // id provincia
      * @param {string} request.latitude
      * @param {string} request.longitude
      * @param {string} request.name_point
      */
  static savePointConnection = (request) => {
    return RestClientObj.instanceAxios.post('/point_connection/add_new_point_connection', request)
      .then(response => response ? response.data : null)
  }

  /** Actualizar nuevo punto de conexion
    *
    * @param {*} request
    * @param {number} request.id_point_connection
    * @param {number} request.canton // id canton
    * @param {number} request.province // id provincia
    * @param {string} request.latitude
    * @param {string} request.longitude
    * @param {string} request.name_point
    */
  static updatePointConnection = (request) => {
    return RestClientObj.instanceAxios.post('/point_connection/update_point_connection', request)
      .then(response => response ? response.data : null)
  }

  /** Eliminar punto de conexion
    *
    * @param {*} request
    * @param {number} request.id_point_connection // id punto de conexion
    */
  static deletePointConnection = (request) => {
    return RestClientObj.instanceAxios.post('/point_connection/delete_point_connection', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de puntos de conexiones
    *
    * @param {*} request
    */
  static listPointsConnections = (request) => {
    return RestClientObj.instanceAxios.post('/point_connection/list_points_connections', request)
      .then(response => response ? response.data : null)
  }

  /** Lista de punto de localizaciones cercanas
  *
  * @param {*} request
  */
  static listLocalizacionCercana = (request) => {
    return RestClientObj.instanceAxios.post('/localizacion_cercana/list_localizacion_cercana', request)
      .then(response => response ? response.data : null)
  }

  /** Eliminar localizacion cercana
  *
  * @param {*} request
  * @param {number} request.id_location_nearest_point // id punto de localizacion cercana
  */
  static deleteLocalizacionCercana = (request) => {
    return RestClientObj.instanceAxios.post('/localizacion_cercana/delete_localizacion_cercana', request)
      .then(response => response ? response.data : null)
  }

  /** Guardar nuevo punto de localizacion cercana
    *
    * @param {*} request
    * @param {string} request.latitude
    * @param {string} request.longitude
    * @param {string} request.label_point
    */
  static saveLocalizacionCercana = (request) => {
    return RestClientObj.instanceAxios.post('/localizacion_cercana/add_new_localizacion_cercana', request)
      .then(response => response ? response.data : null)
  }

  /** Actualizar nuevo punto de localizacion cercana
*
* @param {*} request
* @param {number} request.id_location_nearest_point
* @param {string} request.latitude
* @param {string} request.longitude
* @param {string} request.label_point
*/
  static updateLocalizacionCercana = (request) => {
    return RestClientObj.instanceAxios.post('/localizacion_cercana/update_localizacion_cercana', request)
      .then(response => response ? response.data : null)
  }

  /**
* Guardar nueva ruta
  * @param {*} request
  * @param {number} request.id_route
  * @param {object} request.location_start
  * @param {number} request.location_start.id id del establecimiento inicial
  * @param {string} request.location_start.location_type tipo(establecimiento || punto_conexion)
  * @param {object} request.location_end
  * @param {number} request.location_end.id id del establecimiento final
  * @param {string} request.location_end.location_type tipo(establecimiento || punto_conexion)
  * @param {array<object>} request.waypoints array de paradas waypoint
  * @param {bool} request.waypoints[].stopover
  * @param {object} request.waypoints[].location
  * @param {double} request.waypoints[].location.lat
  * @param {double} request.waypoints[].location.lng
  * @param {array<object>} request.route_path array de ruta completa
  * @param {number} request.timeEstimated
  * @param {number} request.distanceEstimated
  * @param {string} request.overviewPolyline
*/
  static updateRoute = (request) => {
    return RestClientObj.instanceAxios.post('/route/update_route', request)
      .then(response => response ? response.data : null)
  }


  static listRoutes = (request) => {
    return RestClientObj.instanceAxios.post('/route/list_routes', request)
      .then(response => response ? response.data : null)
  }

  static deleteRoute = (request) => {
    return RestClientObj.instanceAxios.post('/route/delete_route', request)
      .then(response => response ? response.data : null)
  }

  static getRubrosVentaCosto = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_rubros_venta_costos', params)
      .then(response => response ? response.data : null)
  }

  /**
 * revisar seleccion de plantilla en reserva despacho
 * @param {number} id_driver
 * @param {number} id_company
 */
  static chequearSeleccionPlantilla = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/check_selection_template', params)
      .then(response => response ? response.data : null)
  }

  static consulta_paradas_guia_viaje = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/consulta_paradas_guia_viaje', params)
      .then(response => response ? response.data : null)
  }

  static eliminarParadaHito = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/eliminar_parada_hito_viaje', params)
      .then(response => response ? response.data : null)
  }

  static registrarTurnoReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/register_turno_reservation', params)
      .then(response => response ? response.data : null)
  }

  static eliminarTurnoReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/delete_turno_reservation', params)
      .then(response => response ? response.data : null)
  }


  /**
 * retorna lista de intervalo de tiempo para envio de notificaciones de resumen de actividades
 */
  static lista_intervalo_tiempo = () => {
    return RestClientObj.instanceAxios.get('/reservation/resumen_interval_tiempos')
      .then(response => response ? response.data : null)
  }

  /**
 * actualizar estado configuracion de notificacion
 */
  static actualizar_estado_notificacion = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_state_notification', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Guardar la configuracion del envio de notificacion de resumen de reservas
   */
  static guardar_configuracion_notificacion_resumen_reservas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_notification_summary_reservation', params)
      .then(response => response ? response.data : null)
  }

  static actualizarObservacionReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_observation_client_reservation', params)
      .then(response => response ? response.data : null)
  }

  static actualizarReservaViaje = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_reservation_trip_type', params)
      .then(response => response ? response.data : null)
  }

  /**
   * obtiene configuracion de compañia de envio de resumen de actividades
   */
  static obtener_configuracion_notificacion_resumen_reservas = () => {
    return RestClientObj.instanceAxios.get('/reservation/get_notification_summary_reservation')
      .then(response => response ? response.data : null)
  }

  /**
 * Lista de porcentage de total de viaje en guia de rescate
 */
  static get_lista_porcentage_viaje = () => {
    return RestClientObj.instanceAxios.get('/reservation/porcentage_total_trip')
      .then(response => response ? response.data : null)
  }


  /**
   * Delete file in s3
   */
  static deleteDriverFile = (coverPhoto) => {
    return RestClientObj.instanceAxios.post('/driver/delete_img', coverPhoto)
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductores
 * @param {number} params.id_driver
 * @param {number} params.name Driver's Name or Lastname
 * @param {number} params.company_id Company of Driver
 * @param {number} params.page
 * @param {number} params.page_size
   */
  static getConductores = params => {
    return RestClientObj.instanceAxios.post('/driver/get_drivers', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Búsqueda de conductores
* @param {number} params.id_driver
* @param {number} params.name Driver's Name or Lastname
* @param {number} params.company_id Company of Driver
* @param {number} params.page
* @param {number} params.page_size
 */
  static getConductoresPizarra = params => {
    return RestClientObj.instanceAxios.post('/driver/get_drivers_pizarra', params)
      .then(response => response ? response.data : null)
  }


  /**
    * resetear password cuenta conductor
   * @param {string} params.username
   * @param {string} params.new_password
   * @param {number} params.id_driver
  */
  static resetPasswordConductor = (params) => {
    return RestClientObj.instanceAxios.post('/users/update_password_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Elimina un conductor
 * @param {number} id_driver
 */
  static deleteDriver = (params) => {
    return RestClientObj.instanceAxios.post('/driver/delete_driver', params)
      .then(response => response ? response.data : null)
  }

  static getCompanies = params => {
    return RestClientObj.instanceAxios.get('/company', params)
      .then(response => response ? response.data : null)
  }

  static getPorts = params => {
    return RestClientObj.instanceAxios.post('/company/get_ports', params)
      .then(response => response ? response.data : null)
  }

  static getDeposits = params => {
    return RestClientObj.instanceAxios.post('/company/get_deposits', params)
      .then(response => response ? response.data : null)
  }

  static bloquearCompania = params => {
    return RestClientObj.instanceAxios.post('/company/save_company_blocked', params)
      .then(response => response ? response.data : null)
  }

  static desbloquearCompania = params => {
    return RestClientObj.instanceAxios.post('/company/save_company_unblocked', params)
      .then(response => response ? response.data : null)
  }

  static saveDrive = params => {
    return RestClientObj.instanceAxios.post('/driver/add_driver', params)
      .then(response => response ? response.data : null)
  }


  static editDriver = params => {
    return RestClientObj.instanceAxios.post('/driver/edit_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
  * eliminar licencias de conductor
  * @param {array<number>} params.id_driver_resources
  * @param {array<number>} params.id_driver_documents
  */
  static eliminarLicencia = params => {
    return RestClientObj.instanceAxios.post('/driver/delete_licenses_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
  * eliminar cedula de conductor
  * @param {number} params.id_resource
  * @param {number} params.id_driver_document
  * @param {number} params.id_driver
  */
  static eliminarCedula = params => {
    return RestClientObj.instanceAxios.post('/driver/delete_identification_card_driver', params)
      .then(response => response ? response.data : null)
  }

  /**
  * agregar licencias de conductor
  * @param {number} params.id_driver
  * @param {array<object>} params.licencias_files
  */
  static agregarNuevaLicencia = params => {
    return RestClientObj.instanceAxios.post('/driver/add_license_driver', params)
      .then(response => response ? response.data : null)
  }

  static actualizarParadas = params => {
    return RestClientObj.instanceAxios.post('reservation/update_trip_stop', params)
      .then(response => response ? response.data : null)
  }

  static actualizarRutaReserva = params => {
    return RestClientObj.instanceAxios.post('reservation/actualizar_paradas', params)
      .then(response => response ? response.data : null)
  }

  static agregarParadaGuia = params => {
    return RestClientObj.instanceAxios.post('reservation/agregar_parada_guia_transporte', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Verifica que la cedula de conductor ingresado no exista
  * @param {string} params.cedula
  * @param {number} params.id_driver
  */
  static verificarCedula = params => {
    return RestClientObj.instanceAxios.post('/driver/verify', params)
      .then(response => response ? response.data : null)
  }

  /****************************  DASHBOARD *****************************/

  /**
  * dashboard facturacion
  */
  static dashboardFacturacion = params => {
    return RestClientObj.instanceAxios.post('/dashboard/dashboard_facturacion', params)
      .then(response => response ? response.data : null)
  }


  /****************************  MÉTODOS DE EMPRESAS *****************************/

  /**
   * Verifica que ruc de empresa ingresado no exista
   * @param {string} params.ruc
   * @param {string} params.id_company | IN CASE OF EDITIN
   */
  static varifyEmpresa = params => {
    return RestClientObj.instanceAxios.post('/company/verify', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductores
   * @param {string} params.ruc
   * @param {string} params.id_company
   * @param {Array<string>} params.company_types | Filter by code | 'TRANS','ADM','IMP','EXP','CLT','DEP','PRT'
   * @param {string} params.business_name
   * @param {numbre} params.page_size
   * @param {numbre} params.page
   */
  static getEmpresas = params => {
    return RestClientObj.instanceAxios.post('/company/get_companies', params)
      .then(response => response ? response.data : null)
  }

  /**
  * guardar datos de edicion rapida
  * @param {array} params.id_reservations
  * @param {integer} params.bill_to
  * @param {integer} params.addressee_to
  */
  static save_edicion_rapida = params => {
    return RestClientObj.instanceAxios.post('/reservation/save_fast_edit', params)
      .then(response => response ? response.data : null)
  }

  /**
  * actualizar fechas de paradas en guia
  * @param {integer} params.id_reservation_milestone_guide
  * @param {array<object>} params.paradas
  */
  static actualizar_fechas_guia = params => {
    return RestClientObj.instanceAxios.post('/reservation/update_dates_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los tipos de empresa
   */
  static getTipoEmpresas = params => {
    return RestClientObj.instanceAxios.get('/company/company_type')
      .then(response => response ? response.data : null)
  }

  static getAsesoresTranspoint = () => {
    return RestClientObj.instanceAxios.get('/company/asesores_transpoint')
      .then(response => response ? response.data : null)
  }

  static getOperativos = () => {
    return RestClientObj.instanceAxios.get('/company/operativos_transpoint')
      .then(response => response ? response.data : null)
  }

  /**
 * Obtiene los tipos de empresa
 */
  static getTipoEmpresasTree = params => {
    return RestClientObj.instanceAxios.get('/company/company_types')
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductor por ID
   * @param {number} id_company
   */
  static getEmpresa = id_company => {
    return RestClientObj.instanceAxios.post('/company/get_company', { id_company })
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductor por ID
   */
  static getLineasNavieras = () => {
    return RestClientObj.instanceAxios.get('/company/get_shipping_lines')
      .then(response => response ? response.data : null)
  }

  /**
   * Registro de Empresa
   * @param {string} params.ruc
   * @param {string} params.business_name
   * @param {string} params.email
   * @param {string} params.address
   * @param {string} params.phone
   * @param {boolean} params.notifications_share
   * @param {string} params.state | 'ACTIVE', 'INACTIVE'
   * @param {string | number} params.company_type Can be type or ID of type company
   * @param {object} params.logo_file
   * @param {string} params.logo_file.path
   */
  static crearEmpresa = params => {
    return RestClientObj.instanceAxios.post('/company/add_company', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Edicion de Empresa
 * @param {number} params.id_company
 * @param {string} params.ruc
 * @param {string} params.business_name
 * @param {string} params.email
 * @param {string} params.address
 * @param {string} params.phone
 * @param {boolean} params.notifications_share
 * @param {string | number} params.company_type Can be type or ID of type company
 * @param {object} params.logo_file
 * @param {string} params.logo_file.path
 */
  static editEmpresa = params => {
    return RestClientObj.instanceAxios.post('/company/edit_company', params)
      .then(response => response ? response.data : null)
  }


  /**
* Eliminar Empresa
* @param {number} params.id_company
*/
  static deleteCompany = params => {
    return RestClientObj.instanceAxios.post('/company/delete_company', params)
      .then(response => response ? response.data : null)
  }

  /**************************       MÉTODOS DE RESERVAS      **************************/
  static getTipoReservas = () => {
    return RestClientObj.instanceAxios.get('/reservation/reservation_type')
      .then(response => response ? response.data : null)
  }

  /**
    * Guardar la lista de hitos/parada de la reserva
    * @param {number} id_reservation
    * @param {array<object>} milestones
    * @param {number} id_template // id de plantilla seleccionada
    **/
  static saveMilestones = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_milestones', params)
      .then(response => response ? response.data : null)
  }


  /**
      * actualizar hito/parada de la reserva
      * @param {integer} id_reservation id de reserva
      * @param {integer} id_reservation_milestone id de hito
      * @param {array<object>} milestones
      * @param {integer} truck_id id camion
      * @param {integer} driver_id id conductor
      * @param {string} container_code contenedor
      * @param {string} observation observaciones
      */
  static updateMilestone = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_milestone', params)
      .then(response => response ? response.data : null)
  }


  /**
    * actualizar hito/parada de la reserva
  * @param {number} origen id location de parada origen
  * @param {number} destino id location de parada destino
  * @param {number} id_reservation_milestone_origen id_milestone origen
  * @param {number} id_reservation_milestone_destino id milestone destino
  * @param {number} id_reservation_milestone_guide id de guia
  * @param {string} fecha_origen
  * @param {string} hora_origen
  * @param {string} fecha_destino
  * @param {string} hora_destino
  * @param {string} estado
    */
  static actualizarHito = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_milestone_state', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Obtener horarios disponibles para reservas en dpw
 */
  static getScheduleDPW = (date) => {
    return RestClientObj.instanceAxios.post('dpw/get_schedule', { appointmentDate: date })
      .then(response => response ? response.data : null);
  };


  static getDataDpw = (id_reservation, id_guide) => {
    return RestClientObj.instanceAxios.get('dpw/get_or_create_dpw', {
      params: {
        id_reservation: id_reservation,
        id_guide: id_guide
      }
    })
      .then(response => response ? response.data : null)
      .catch(error => {
        console.error("Error al obtener o crear DPW:", error);
        return null;
      });
  }

  static deleteDpw = (params) => {
    return RestClientObj.instanceAxios.post('dpw/delete_dpw', params)
      .then(response => response ? response.data : null)
  }

  static updateDpw = (id_reservation, data) => {
    return RestClientObj.instanceAxios.post('dpw/update_dpw', {
      id_reservation: id_reservation,
      data: data
    })
      .then(response => response ? response.data : null)
  }

  static sendDpw = (id_reservation, data) => {
    return RestClientObj.instanceAxios.post('dpw/send_dpw', {
      id_reservation: id_reservation,
      data: data
    })
      .then(response => response ? response.data : null)

  }
  /**
   * lista de guias de una reserva
   * @param {integer} id_reservation id de reserva
  */
  static getGuiasReservas = (params, token = null) => {
    if (token)
      return axios({
        method: 'post',
        headers: {
          'Authorization': token
        },
        url: `${baseURL}/services_no_logging/monitoreo/lista_guias`,
        data: params
      }).then(resp => resp ? resp.data : null)


    return RestClientObj.instanceAxios.post('/reservation/get_reservation_guide', params)
      .then(response => response ? response.data : null)
  }

  static cerrarGuias = () => {
    return RestClientObj.instanceAxios.get('/reservation/close_guides')
      .then(response => response ? response.data : null)
  }

  static getGuiasReservasCerrada = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reservation_guide_cerrada', params)
      .then(response => response ? response.data : null)
  }



  /**
 * lista de guias de una reserva
*/
  static get_logo_transportista = (params, token = null) => {
    return axios({
      method: 'get',
      headers: {
        'Authorization': token
      },
      url: `${baseURL}/services_no_logging/monitoreo/get_logo_transport`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
  * todas las paradas de las guias de una reserva
  * @param {integer} id_reservation id de reserva
 */
  static paradasReserva = params => {
    return RestClientObj.instanceAxios.post('/reservation/get_milestones_by_reservation', params)
      .then(response => response ? response.data : null)
  }
  /**
   * lista de guias cerradas
   * @param {integer} id_reservation id de reserva
  */
  static getGuiasCerradas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reservation_guide_close', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Datos generales de reserva en reporte
   * @param {integer} id_reservation id de reserva
  */
  static getDatosGeneralesReporte = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reporte_datos_generales', params)
      .then(response => response ? response.data : null)
  }

  static falso_flete_options = () => {
    return RestClientObj.instanceAxios.get('/reservation/falso_flete_options')
      .then(response => response ? response.data : null)
  }

  static service_criteria_list = () => {
    return RestClientObj.instanceAxios.get('/quality_service_criteria/criteria_list')
      .then(response => response ? response.data : null)
  }

  /**
   * lista de log SAP
  */
  static getLogSAP = (params) => {
    return RestClientObj.instanceAxios.post('/logs/log_sap', params)
      .then(response => response ? response.data : null)
  }

  /**
 * lista de log SAP
*/
  static getLogParticularSAP = (params) => {
    return RestClientObj.instanceAxios.post('/logs/log_sap_particulares', params)
      .then(response => response ? response.data : null)
  }

  /**
* lista de log SAP
*/
  static getLogReembolsosSAP = (params) => {
    return RestClientObj.instanceAxios.post('/logs/log_sap_reembolsos', params)
      .then(response => response ? response.data : null)
  }

  static getLogCostoParticularesSAP = (params) => {
    return RestClientObj.instanceAxios.post('/logs/log_sap_costo_particulares', params)
      .then(response => response ? response.data : null)
  }

  /**
   * lista de paradas de guias creadas
   * @param {integer} id_reservation id de reserva
  */
  static getParadasGuias = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_locations_monitoring_user', params)
      .then(response => response ? response.data : null)
  }

  /**
  * cerrar guia
  * @param {integer} params.id_reservation_milestone_guide id guia
  * @param {string} params.type_guide tipo de guia
  * @param {integer} params.id_reservation id reserva
  * @param {date} params.departure_date_guide fecha de salida de planta
  * @param {date} params.arrival_date_guide fecha de llegada de planta
  * @param {date} params.arrival_date_port_deposit fecha de puerto deposito
  * @param {string} params.container_reclosing contenedor reenganche
  * @param {string} params.seal_reclosing sello de reengancha
  * @param {string} params.container_code contenedor
  * @param {string} params.seal_vacio sello vacio
  * @param {string} params.seal_shipping_full sello naviera cargado
  * @param {string} params.seal_shipping_full_2 sello naviera cargado 2
  * @param {string} params.observations observaciones
  * @param {boolean} params.false_flete check de falso flete
  * @param {integer} params.option_falso_flete en caso de falso flete opcion de avance de la ruta
  * @param {array} params.registros registros de temperaturas de la carga en la guia
  */
  static cerrarGuia = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/close_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
* actualizar cerrar guia
* @param {integer} params.id_reservation_milestone_guide id guia
* @param {string} params.type_guide tipo de guia
* @param {integer} params.id_reservation id reserva
* @param {date} params.departure_date_guide fecha de salida de planta
* @param {date} params.arrival_date_guide fecha de llegada de planta
* @param {date} params.arrival_date_port_deposit fecha de puerto deposito
* @param {string} params.container_reclosing contenedor reenganche
* @param {string} params.seal_reclosing sello de reengancha
* @param {string} params.container_code contenedor
* @param {string} params.seal_vacio sello vacio
* @param {string} params.seal_shipping_full sello naviera cargado
* @param {string} params.seal_shipping_full_2 sello naviera cargado 2
* @param {string} params.observations observaciones
* @param {boolean} params.false_flete check de falso flete
* @param {integer} params.option_falso_flete en caso de falso flete opcion de avance de la ruta
* @param {array} params.registros registros de temperaturas de la carga en la guia
*/
  static actualizarGuiaCerrada = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_close_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
* consulta de tipo de activiades de guias
*/
  static get_tipo_actividades_guias = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_guide_activities', params)
      .then(response => response ? response.data : null)
  }

  /**
  * eliminar guia
  * @param {integer} id_reservation_milestone_guide
 */
  static deleteGuia = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/delete_reservation_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
   * crear una guia de hitos seleccionados
   * @param {integer} id_reservation id de reserva
   * @param {array<object>} milestones lista de hitos seleccionados
  */
  static crearGuia = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_reservation_guide', params)
      .then(response => response ? response.data : null)
  }

  static crearGuiaDevolucion = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_reservation_guide_return_container', params)
      .then(response => response ? response.data : null)
  }

  /**
   * crear una guia de hitos seleccionados
   * @param {number} id_reservation_milestone_guide id guia
   * @param {number} id_reservation id de reserva
   * @param {array<object>} milestones lista de hitos seleccionados
   * @param {object} values datos guia
   * @param {number} values.chasis
   * @param {number} values.generator
   * @param {string} values.container_code
   * @param {number} values.driver_id
   * @param {number} values.truck_id
   * @param {string} values.observation
  */
  static crearGuiaRescate = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_reservation_guide_rescue', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Habilitar guia
  * @param {number} id_reservation_milestone_guide id guia
 */
  static habilitarGuia = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reverse_reservation_guide_closed', params)
      .then(response => response ? response.data : null)
  }

  /**
    * quitar entrada patio
    * @param {number} id_reservation_milestone_guide id guia
   */
  static resetEntradaPatio = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reset_arrival_date', params)
      .then(response => response ? response.data : null)
  }


  /**
    * quitar salida patio
    * @param {number} id_reservation_milestone_guide id guia
   */
  static resetSalidaPatio = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reset_departure_date', params)
      .then(response => response ? response.data : null)
  }

  /**
    * lista de motivos para anulacion de reserva
   */
  static menu_reserva_motivo = () => {
    return RestClientObj.instanceAxios.get('/reservation/menu_reserva_motivo')
      .then(response => response ? response.data : null)
  }

  /**
    * anular la reserva
    * @param {number} id_reservation
    * @param {number} motivo
    * @param {text} comentario
   */
  static cancel_reservation = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/cancel_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Generar documento guia
   * @param {number} id_reservation_milestone_guide
   */
  static downloadDocumentGuide = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/generate_document_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Generar documento particular
   * @param {number} id_reservation_milestone_guide
   */
  static downloadDocumentParticular = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/generate_document_vale_caja_particular', params)
      .then(response => response ? response.data : null)
  }


  /**
   * actualiza estado de las reservaciones y solicitud (pendiente, aceptar o rechazar)
   * @param {number} id_reservation_group
   * @param {array<Reserva>} reservations
   * Reserva Param
   * @typedef Reserva
   * @property {number} id_reservation
   * @property {string} state
   * @property {number} id_truck
   * @property {string} appointment_date
   * @property {string} appointment_time
   *
   * @param {boolean} todos // indica si se aplica a todas las reservas
   * @param {boolean} estadoTodos   //estado para todas las reservas (true:acepta todas, false:rechaza todas)
   * @param {object} tarifa //objeto tarifa con atributo id_fee y fee_cost
   * @param {string} costo // valor de costo estimado
   **/
  static updateEstadoReservas = (param) => {
    return RestClientObj.instanceAxios.post('/reservation/update_reservation_pending_state', param)
      .then(response => response ? response.data : null)
  }

  /**
   * actualiza estado de las reservaciones y solicitud (pendiente, aceptar o rechazar)
   * @param {number} id_reservation_group | In case of all reservation
   * @param {array<Reserva>} reservations
   * Reserva Param
   * @typedef Reserva
   * @property {number} id_reservation
   * @property {string} state
   * @property {number} id_truck
   **/
  static updateSubReservas = (param) => {
    return RestClientObj.instanceAxios.post('/reservation/update_sub_reservation', param)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener lista de reservas pendientes de aceptar o rechazar
  * @param {string} params.date_initial  | Format: 'yyyy-mm-dd'
  * @param {string} params.date_final  | Format: 'yyyy-mm-dd'
   * @param {string} params.special_filter | BY_DATE, ALL_PENDING, EXPIRED
  * @param {number} params.page_size
  * @param {number} params.page
  **/
  static getReservasPendientes = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_list_pending', params)
      .then(response => response ? response.data : null)
  }


  /**
* Obtener lista de reservas en curso
* @param {number} body.page_size
* @param {number} body.page
**/
  static getReservasEnCurso = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_list_in_progress', params)
      .then(response => response ? response.data : null)
  }

  /**
* Obtener lista de reservas programadas
* @param {number} body.page_size
* @param {number} body.page
**/
  static getReservasProgramadas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_list_programming', params)
      .then(response => response ? response.data : null)
  }


  /**
* Obtener lista de reservas aceptadas
* @param {number} body.page_size
* @param {number} body.page
**/
  static getReservasAprobadas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_list_approved', params)
      .then(response => response ? response.data : null)
  }


  static asignar_tarifa_reserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/asociar_reserva_tarifa', params)
      .then(response => response ? response.data : null)
  }

  static asignar_costo_particulares_reserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/asociar_reserva_costo_particulares', params)
      .then(response => response ? response.data : null)
  }

  /**
* Obtener lista de reservas finalizadas
* @param {number} body.id_reservation
* @param {number} body.page_size
* @param {number} body.page
**/
  static getReservasFinalizadas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_list_finished', params)
      .then(response => response ? response.data : null)
  }


  /**
  * Generar orden(es) de pago de reserva(s)
  * @param {array<object>} body.reservations
  * @param {array<object>} body.reservations_log
  * @param {string} body.wo work order
  * @param {array<object>} body.servicios_costo_particular
  **/
  static generarOrdenPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/generate_payment_order', params)
      .then(response => response ? response.data : null)
  }

  static eliminarServicioOrdenPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/delete_service_payment_order', params)
      .then(response => response ? response.data : null)
  }

  static editarServicioOrdenPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/edit_service_payment_order', params)
      .then(response => response ? response.data : null)
  }

  static agregarServicioOrdenPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/add_service_payment_order', params)
      .then(response => response ? response.data : null)
  }


  static eliminarCostoProveedor = (params) => {
    return RestClientObj.instanceAxios.post('/costs/delete_costs_provider', params)
      .then(response => response ? response.data : null)
  }

  static editarCostoProveedor = (params) => {
    return RestClientObj.instanceAxios.post('/costs/edit_costs_provider', params)
      .then(response => response ? response.data : null)
  }

  static agregarCostoProveedor = (params) => {
    return RestClientObj.instanceAxios.post('/costs/add_costs_provider', params)
      .then(response => response ? response.data : null)
  }



  /**
  * Reenviar orden a SAP
  * @param {array<object>} body.reservations
  **/
  static reenviarOrdenSAP = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/resend_order_sap', params)
      .then(response => response ? response.data : null)
  }

  static reenviarOrdenCostoProveedorSAP = (params) => {
    return RestClientObj.instanceAxios.post('/costs/resend_order_cost_provider_sap', params)
      .then(response => response ? response.data : null)
  }


  static reenviarOrdenReembolsoSAP = (params) => {
    return RestClientObj.instanceAxios.post('/refunds/resend_order_refund_sap', params)
      .then(response => response ? response.data : null)
  }

  static eliminarOrdenCostoProveedorSAP = (params) => {
    return RestClientObj.instanceAxios.post('/costs/delete_order_cost_provider_sap', params)
      .then(response => response ? response.data : null)
  }


  static eliminarOrdenReembolsoSAP = (params) => {
    return RestClientObj.instanceAxios.post('/refunds/delete_order_refund_sap', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener lista de ordenes
  * @param {number} body.id_reservation
  * @param {number} body.id_order
  * @param {string} body.type // COBRAR o PAGAR
  * @param {number} body.page_size
  * @param {number} body.page
  **/
  static getOrdenesPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/get_payment_orders', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener cost de reserva
  * @param {number} body.id_company
  * @param {number} body.id_cliente
  * @param {array<object>} body.ruta
  * @param {boolean} body.reserva_vacio
  * @param {char} body.movimiento
  * @param {array<number>} body.servicios
  **/
  static get_costo_particular = (params) => {
    return RestClientObj.instanceAxios.post('/fee/get_cost_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
 * eliminar orden
 * @param {number} body.number_order
 **/
  static deleteOrden = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/delete_order', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener informacion de una orden
  * @param {string} body.number_order
  **/
  static getInfoOrdenPago = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/get_payment_order', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Consultar plantilla de generacion de hitos para la creaciono de guias
   **/
  static getPlantillas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/despacho_template_milestones', params)
      .then(response => response ? response.data : null)
  }


  /**
  * Obtener informacion de una orde facturada
  * @param {string} body.number_order
  **/
  static getInfoOrdenFacturada = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/get_invoice', params)
      .then(response => response ? response.data : null)
  }


  /**
  * Facturar una orden de pago
  * @param {object} orden objeto orden
  * @param {number} orden.id_payment_order // id de la orden
  * @param {float} orden.subtotal // subtotal de la orden
  * @param {float} orden.iva // iva de la orden
  * @param {float} orden.discount // descuento aplicado de la orden
  * @param {float} orden.total // total de la orden
  * @param {string} orden.company_client_name // razon_social cliente
  * @param {string} orden.company_client_address // direccion cliente
  * @param {string} orden.company_client_email // correo cliente
  * @param {string} orden.company_client_ruc // ruc cliente
  * @param {array<object>} orden.reservations // lista de reservas asociadas a la orden
  * @param {number} orden.reservations.reservation_id // id reserva asociada a la orden
  * @param {array<object>} orden.reservations.services // rubros asociada a la reserva
  * @param {string} orden.reservations.services.description // descripcion de rubro asociada a la reserva
  * @param {float} orden.reservations.services.subtotal // subtotal de rubro asociada a la reserva
  * @param {float} orden.reservations.services.iva // iva de rubro asociada a la reserva
  * @param {float} orden.reservations.services.discount // descuento de rubro asociada a la reserva
  */
  static facturarOrden = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/facturar_orden', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener lista de ordenes facturadas
  * @param {number} body.id_reservation
  * @param {number} body.id_order
  * @param {number} body.page_size
  * @param {number} body.page
  **/
  static getOrdenesFacturadas = (params) => {
    return RestClientObj.instanceAxios.post('/payment_order/get_invoices', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtener lista de tipos de alertas de monitoreo
  **/
  static getAlertas = () => {
    return RestClientObj.instanceAxios.get('/alert/get_alerts')
      .then(response => response ? response.data : null)
  }

  /**
 * Tipos de alerta vista monitoreo
 **/
  static getListaTiposAlertas = () => {
    return RestClientObj.instanceAxios.get('/alert/list_alert_monitoring')
      .then(response => response ? response.data : null)
  }

  /**
 * Obtener una alerta
 **/
  static getAlerta = (params) => {
    return RestClientObj.instanceAxios.post('/alert/get_alert', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Actualizar estado de tipo de alerta de monitoreo
  **/
  static updateAlert = (param) => {
    return RestClientObj.instanceAxios.post('/alert/update_alert', param)
      .then(response => response ? response.data : null)
  }

  /**
 * Deshabilitar alerta
 **/
  static disabledAlert = (param) => {
    return RestClientObj.instanceAxios.post('/alert/disabled_alert', param)
      .then(response => response ? response.data : null)
  }

  /**
 * historial de alerta de una guia
 **/
  static historyAlert = (param) => {
    return RestClientObj.instanceAxios.post('/alert/history_alert', param)
      .then(response => response ? response.data : null)
  }


  /**
  * Lista de opciones intervalos de nuevas alertas
  **/
  static optionIntervalAlert = () => {
    return RestClientObj.instanceAxios.get('/alert/option_interval_alert')
      .then(response => response ? response.data : null)
  }

  /**
  * Guardar nueva alerta
  **/
  static saveNewAlert = (param) => {
    return RestClientObj.instanceAxios.post('/alert/save_alert', param)
      .then(response => response ? response.data : null)
  }

  /**
  * Guardar nuevo recordatorio
  **/
  static saveNewAlertReminder = (param) => {
    return RestClientObj.instanceAxios.post('/alert/save_alert_reminder', param)
      .then(response => response ? response.data : null)
  }


  /**
   * @param {object} params
   * @param {number} params.id_reservation
   * @param {number} params.id_client
   * @param {number} params.id_transport
   * @param {number} params.id_addressee
   * @param {number} params.id_bill_to
   * @param {string} params.date | format: 'YYYY-MM-DD'
   * @param {string} params.reservation_type | reservation type code - 'I', 'E'
   * @param {number} params.state
   * -- Container filters
   * @param {number} params.id_shipping_line
   * @param {number} params.booking
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getReservas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reservations', params)
      .then(response => response ? response.data : null)
  }

  static reservasPorFacturar = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reservations_to_invoice', params)
      .then(response => response ? response.data : null)
  }

  /**
 * @param {object} params
 * @param {number} params.id_reservation
 * @param {number} params.id_client
 * @param {number} params.id_transport
 * @param {number} params.id_addressee
 * @param {number} params.id_bill_to
 * @param {string} params.date | format: 'YYYY-MM-DD'
 * @param {string} params.reservation_type | reservation type code - 'I', 'E'
 * @param {number} params.state
 * -- Container filters
 * @param {number} params.id_shipping_line
 * @param {number} params.booking
 * @param {number} params.page
 * @param {number} params.page_size
 */
  static getListaReservas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_lista_reservas', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene disponibilidad por días de un transportista
   * @param {object} params
   * @param {number} params.id_company
   */
  static getDiasDisponibles = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_aviability', params)
      .then(response => response ? response.data : null)
  }

  /**
    * @param {object} params
    * @param {number} params.page
    * @param {number} params.page_size
    */
  static getDespachos = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_despachos', params)
      .then(response => response ? response.data : null)
  }

  /**
   * @param {object} params
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getListaDespachos = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_lista_despachos', params)
      .then(response => response ? response.data : null)
  }

  /**
  * @param {object} params
  * @param {number} params.id_reservation_milestone
  * @param {number} params.id_reservation
  */
  static finalizarHito = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_milestone_finished', params)
      .then(response => response ? response.data : null)
  }

  /**
   * @param {object} params
  * @param {number} params.id_reservation
  */
  static getDespacho = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_despacho', params)
      .then(response => response ? response.data : null)
  }

  /**
  * @param {object} params
 * @param {number} params.id_reservation
 */
  static getReservation = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Save a reservation request from a client
   * Reservation PARAMS
   * @param {string} params.id_reservation_group - If the reservation is part of a reservation's group
   * @param {string} params.appoiment_date - Referencial date of reservation -  format: 'YYYY-MM-DD'
   * @param {string} params.appoiment_time - Referencial time of reservation -  format: 'HH:mm'
   * @param {string} params.id_reservation_type
   * @param {number} params.id_client - when user belongs to COMPANY, this is te company_client, but get a company received.
   * @param {number} params.id_transport - when user belongs to COMPANY, this is te company_client, but get a company received.
   * @param {number} params.id_bill_to - OPTIONAL only for SPECIAL CLIENT
   * @param {number} params.id_addressee - OPTIONAL only for SPECIAL CLIENT
   * @param {string} params.observation - default: 'PENDING'
   * @param {string} params.state - default: 'PENDING'
   * Container PARAMS
   * @param {string} params.ship - SHIP NAME
   * @param {string} params.amount_trucks
   * @param {string} params.id_shipping_line
   * @param {array[file]} params.lista_documentos
   * @param {bool} params.secure
   * @param {string} params.id_container_type
   * @param {string} params.booking
   * @param {string} params.cut_off fecha de cut off
   * @param {string} params.ecas fecha de caducidad ecas
   * @param {number} params.temperature
   * @param {number} params.weight
   * @param {number} params.id_product - If the product was choseen
   * @param {string} params.name_product - If the product was created
   * @param {string} params.reference
   * Trip PARAMS
   * @typedef {Object} TripStop
   * @property {number} id_location
   * @property {number} order
   * @property {number} wait_time - OPTIONAL
   * @property {string} arrival_date - Referencial date of arrival -  format: 'YYYY-MM-DD'
   * @property {string} arrival_time - Referencial time of arrival -  format: 'HH:mm'
   * @property {string} state - default: 'PENDING'
   * @param {Array<TripStop>} params.trip
   */
  static saveReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save', params)
      // return RestClientObj.instanceAxios.post('/reservation/save-no',params) // BORRAR
      .then(response => response ? response.data : null)
  }

  /**
   * Cancel one sub-reservation.
   * @param {number} params.id_reservation
   */
  static cancelarSubReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/cancel_sub_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Creation of request of sub-reservation.
   * @param {object} params
   * @param {number} params.id_company
   * @param {Array<Reservation>} params.reservations
   * @typedef Reservation
   * @property {number} id_reservation
   * @property {Array<Service>} services
   * @typedef Service
   * @property {number} id_service | It's optional, id_service or id_fee_service or code_service is required
   * @property {string} code_service | It's optional, id_service or id_fee_service or code_service is required
   * @property {number} id_fee_service | It's optional, id_service or id_fee_service or code_service is required
   * @property {number} value_sub_reserve
   */
  static crearSubReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/create_sub_reservation', params)
      // return RestClientObj.instanceAxios.post('/reservation/save-no',params) // BORRAR
      .then(response => response ? response.data : null)
  }
  /**
   * Update reservation
   * @param {object} params
   * @param {number} params.id_reservation_group
   * @param {number} params.id_reservation
   * @param {number} params.id_truck
   * @param {number} params.id_driver
   * @param {string} params.booking
   * @param {string} params.shipping_stamp
   * @param {number} params.weight
   * @param {number} params.product
   * @param {string} ecas fecha caducidad ecas
   * @param {string} cut_off fecha de cut off
   * @param {number} params.id_fee
   * @param {object} params.documento
   * @param {string} params.documento.descripcion
   * @param {array<File>}params.documento.files
   */
  static updateReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Reject reservation
   * @param {object} params
   * @param {number} params.id_reservation
   * @param {number} params.id_reservation_group
   *
   */
  static rechazarReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reject_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Reject reservation
   * @param {object} params
   * @param {number} params.id_reservation
   * @param {number} params.id_reservation_group
   */
  static rechazarSubReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reject_sub_reservation', params)
      .then(response => response ? response.data : null)
  }

  static crearSolicitudReserva = () => {
    return RestClientObj.instanceAxios.get('/reservation/create_reservation_request')
      .then(response => response ? response.data : null)
  }

  static guardarProgramacionReservas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_programacion_reserva', params)
      .then(response => response ? response.data : null)
  }

  static actualizarProgramacion = params => {
    return RestClientObj.instanceAxios.post('/reservation/edit_programacion_reserva', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los tipos de contenedores
   */
  static getTiposContenedor = () => {
    return RestClientObj.instanceAxios.get('/reservation/get_container_type')
      .then(response => response ? response.data : null)
  }

  /**
  * Resetear hitos de una reserva
  */
  static resetHitos = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reset_milestones', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los productos
   * @param {string} name | Nombre del producto
   */
  static getProducto = (name) => {
    return RestClientObj.instanceAxios.post('/reservation/get_products', { name: name })
      .then(response => response ? response.data : null)
  }

  static getProductosTPT = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_products_tpt', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Desactivar producto
   * @param {int} id_product | id del producto
   */
  static desactivarProducto = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/delete_product', params)
      .then(response => response ? response.data : null)
  }

  /**
  * eliminar productos
  * @param {string} product_name | Nombre del producto
  * @param {int} id_product | id del producto
  */
  static editarProducto = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/edit_product', params)
      .then(response => response ? response.data : null)
  }

  static guardarProducto = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_product', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Desactivar linea
 * @param {int} id_shipping_line | id linea naviera
 */
  static desactivarLineaNaviera = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/delete_shipping_line', params)
      .then(response => response ? response.data : null)
  }

  /**
  * eliminar productos
  * @param {string} shipping_line_name | Nombre linea naviera
  * @param {int} id_shipping_line | id linea naviera
  */
  static editarLineaNaviera = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/edit_shipping_line', params)
      .then(response => response ? response.data : null)
  }

  static guardarLineaNaviera = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_shipping_line', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Obtiene los productos
   * @param {string} name | Nombre del producto
   */
  static getBuques = (name) => {
    return RestClientObj.instanceAxios.post('/reservation/get_ships', { name: name })
      .then(response => response ? response.data : null)
  }

  /**
   * Get gantt chart, TRUCK vs RESERVATION
   * @param {string} params.date_initial  | Format: 'yyyy-mm-dd'
   * @param {string} params.date_final  | Format: 'yyyy-mm-dd'
   * @param {number} params.id_company
   */
  static getGantt = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reservation_gantt', params)
      .then(response => response ? response.data : null)
  }

  /**
   * actualiza estado de las reservaciones y solicitud (pendiente, aceptar o rechazar)
   * @param {number} params.id_company
   * @param {array<TripStop>} params.reservation_trip
   */
  static getETA = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_eta', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Crea un conductor asociado a un empresa, ademas registra archivos multimedia
  * @param {*} params
  * @param {string} params.driver_name nombre del conductor
  * @param {string} params.driver_last_name apellido del conductor
  * @param {string} params.address direccion del conductor
  * @param {string} params.phone telefono del conductor
  * @param {string} params.identification_card cedula/pasaporte del conductor
  * @param {string} params.birthday fecha de nacimiento del conductor
  * @param {string} params.username nombre de usuario si el campo de inicio_sesion es true
  * @param {string} params.password contrasena si el campo de inicio_sesion es true
  * @param {boolean} params.inicio_sesion si es true se registra el inicio de sesion del conductor con el username y password
  * @param {Array<string>} params.companies array con id's de companies asociados a conductor
  * @param {FileObject} params.avatar
  * @param {Array<FileObject>} params.identification_card_files
  * ---------------------------------------------------------------
  * @typedef FileObject
  * @property {string} name
  * @property {string} url
  * @property {string} type | File type: 'image', 'pdf'
  */
  static saveDriver = (params) => {
    return RestClientObj.instanceAxios.post('/driver/add_driver', params)
      .then(response => response ? response.data : null)
  }

  static get_generos = () => {
    return RestClientObj.instanceAxios.get('/driver/get_generos')
      .then(response => response ? response.data : null)
  }

  static get_estado_civil = () => {
    return RestClientObj.instanceAxios.get('/driver/get_estado_civil')
      .then(response => response ? response.data : null)
  }

  static get_instrucciones = () => {
    return RestClientObj.instanceAxios.get('/driver/get_instrucciones')
      .then(response => response ? response.data : null)
  }

  static get_tipos_sangre = () => {
    return RestClientObj.instanceAxios.get('/driver/get_tipo_sangre')
      .then(response => response ? response.data : null)
  }

  static get_permiso_programacion = () => {
    return RestClientObj.instanceAxios.get('/reservation/get_permiso_programacion')
      .then(response => response ? response.data : null)
  }


  /**
   * Crea un conductor asociado a un empresa, ademas registra archivos multimedia
   * @param {*} params
   * @param {number} params.id_driver
   * @param {number} params.identification_card
   * @param {Array<FileObject>} params.files
   * ---------------------------------------------------------------
   * @typedef FileObject
   * @property {string} name
   * @property {string} url
   * @property {number} id_license_type
   * @property {string} resource_type | 'profile_image', 'identification_card', 'license'
   * @property {string} type | File type: 'image', 'document'
   */
  static saveFileDriver = (params) => {
    return RestClientObj.instanceAxios.post('/driver/add_files', params)
      .then(response => response ? response.data : null)
  }

  /**************************  MÉTODOS DE CARGA DE ARCHIVOS **************************/
  /**
   * Get url for put a file by s3
   * @param {string} filename
   * @param {string} type | tipo de recurso multimedia LICENCIA, FOTO
   * @param {string} identification | cedula conductor/ruc company
   * @param {string} perfil | Level 1 - 'COMPANY', 'DRIVER', 'TRUCK', 'GENERATOR',  'CHASSIS'
   * @param {number} id_reserva id de reserva aplica para documentos en reservas: RETIRO
   * @param {string} tipo_documento_reserva tipo de documento de una reserva: RETIRO
   *
   */
  static preSignedUrlFiles = (coverPhoto) => {
    return RestClientObj.instanceAxios.post('/files/post_s3-url', coverPhoto)
      .then(response => response ? response.data : null)
  }


  /**************************  Métodos de ESTABLECIMIENTOS  **************************/
  /**
   * Obtiene los establecimientos
   * @param {string} params.name
   * @param {string} params.address
   * @param {string} params.zone
   * @param {Array<string>} body.types | code location types: 'PLT' 'PAR' 'DEP' 'PRT'
   * @param {number} params.company_id
   * @param {number} params.priority_company_id
   * @param {number} params.parish_id
   * @param {number} params.canton_id
   * @param {number} params.province_id
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getEstablecimientos = (params) => {
    return RestClientObj.instanceAxios.post('/location/get_locations', params)
      .then(response => response ? response.data : null)
  }

  static lista_horas_libres = () => {
    return RestClientObj.instanceAxios.get('/location/get_free_hours_default')
      .then(response => response ? response.data : null)
  }

  static lista_motivos_bitacora = () => {
    return RestClientObj.instanceAxios.get('/bitacora/get_bitacora_motivos')
      .then(response => response ? response.data : null)
  }

  static guardarBitacora = (params) => {
    return RestClientObj.instanceAxios.post('/bitacora/save_bitacora', params)
      .then(response => response ? response.data : null)
  }

  static actualizarBitacora = (params) => {
    return RestClientObj.instanceAxios.post('/bitacora/update_bitacora', params)
      .then(response => response ? response.data : null)
  }

  static listaBitacoraGuia = (params) => {
    return RestClientObj.instanceAxios.post('/bitacora/get_lista_bitacora', params)
      .then(response => response ? response.data : null)
  }

  static eliminarBitacora = (params) => {
    return RestClientObj.instanceAxios.post('/bitacora/delete_bitacora', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Actualizar ciudad/canton
   * @param {number} body.id_provincia id provincia
   * @param {number} body.id_canton id canton/ciudad
   * @param {string} body.ciudad nombre de ciudad
   * @param {string} body.provincia nombre de provincia
   * @param {string} body.point_connection_id
   */
  static actualizarCanton = (params) => {
    return RestClientObj.instanceAxios.post('/canton/update_canton', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Consulta de datos extras de canton en base al id_canton o a la ciudad del id_location
   * @param {number} params.id_canton
   * @param {number} params.id_location
   * @returns {object} objeto con datos extras de ciudad (viaticos, km, peaje, etc)
   */
  static get_canton_extras = (params) => {
    return RestClientObj.instanceAxios.post('/location/get_extras_canton', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Eliminar ciudad/canton
   * @param {number} body.id_canton id canton/ciudad
   */
  static eliminarCanton = (params) => {
    return RestClientObj.instanceAxios.post('/canton/delete_canton', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Agregar canton
   * @param {number} body.id_provincia id provincia
   * @param {string} body.ciudad nombre de ciudad
   * @param {string} body.point_connection_id
   */
  static agregarCanton = (params) => {
    return RestClientObj.instanceAxios.post('/canton/add_canton', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Elimina un establecimiento
   * @param {number} params.id_location
   */
  static eliminarEstablecimiento = (params) => {
    return RestClientObj.instanceAxios.post('/location/delete_location', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Obtiene las provincias del país
   * @param {string} params.name
   */
  static getProvincias = (params) => {
    return RestClientObj.instanceAxios.post('/location/get_province', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Obtiene los cantones del país/provincia
   * @param {string} params.name
   * @param {number} params.id_province
   */
  static getCantones = (params) => {
    return RestClientObj.instanceAxios.post('/location/get_cantons', params)
      .then(response => response ? response.data : null)

  }


  /**
   * Consulta de lista de puntos de conexion
   */
  static getPuntosConexion = () => {
    return RestClientObj.instanceAxios.get('/location/get_points_connections')
      .then(response => response ? response.data : null)

  }

  /**
  * Get tipo de establecimientos
  * @param {Array<string>} body.codes | code location types: 'PLT' 'PAR' 'DEP' 'PRT'
  */
  static getTipoEstablecimiento = (params) => {
    return RestClientObj.instanceAxios.post('/location/get_location_type', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Obtiene los tipos de licencias de conductor
   */
  static getTipoLicencias = () => {
    return RestClientObj.instanceAxios.get('/driver/get_driver_license_type')
      .then(response => response ? response.data : null)
      .catch(error => error.response)
  }

  /**
   * Agrupa latitud y longitud para Maps
   * @typedef {Object} LatLng
   * @property {number} lat - Latitud
   * @property {number} lng - Longitud
   */

  /**
   * Crea un nuevo establecimiento
   * @param {number} id_company
   * @param {number} id_location_type
   * @param {string} name
   * @param {number} id_province
   * @param {number} id_canton
   * @param {string} zone
   * @param {string} address
   * @param {string} name_contact
   * @param {string} phone_contact
   * @param {Array<LatLng>} polygon
   * @param {LatLng} center
   */
  static saveEstablecimiento = (params) => {
    return RestClientObj.instanceAxios.post('/location/add_location', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Crea un nuevo establecimiento
   * @param {number} id_location
   * @param {number} id_location_type
   * @param {string} name
   * @param {string} zone
   * @param {string} address
   * @param {string} name_contact
   * @param {string} phone_contact
   * @param {Array<LatLng>} polygon
   * @param {LatLng} center
   */
  static updateEstablecimiento = (params) => {
    return RestClientObj.instanceAxios.post('/location/update_location', params)
      .then(response => response ? response.data : null)
  }

  static updateEstadoCamion = (params) => {
    return RestClientObj.instanceAxios.post('/truck/update_status_truck', params)
      .then(response => response ? response.data : null)
  }

  static getEstadosCamion = () => {
    return RestClientObj.instanceAxios.get('/truck/get_status_truck')
      .then(response => response ? response.data : null)
  }

  /**
     * Lista de marcas de camiones
     *  @param {string} name nombre de marca
   */
  static getBrandTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/brand_truck', params)
      .then(response => response ? response.data : null)
  }

  static getCamionesHabilitados = (request) => {
    return RestClientObj.instanceAxios.post('/truck/trucks_availables_monitoring', request)
      .then(response => response ? response.data : null)
  }

  /**
   * Guardar observacion de camion
   *  @param {string} observation observacion de camion
   * @param {integer} id_truck id de camion
 */
  static saveObservationTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/save_observation_truck', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Eliminar observacion de camion
  *  @param {integer} id_truck id de camion
*/
  static deleteObservationTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/delete_observation_truck', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Obtiene informacion de ultima guia de un camion
   *  @param {string} id_truck nombre de marca
 */
  static get_info_guide_by_truck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/get_info_guide_by_truck', params)
      .then(response => response ? response.data : null)
  }

  /**
    * Lista de ejes de camiones
  */
  static getAxesTruck = () => {
    return RestClientObj.instanceAxios.get('/truck/axes_truck')
      .then(response => response ? response.data : null)
  }

  /**
     * Lista de historia de certificados de un camion
   */
  static getTruckCertificates = (data) => {
    return RestClientObj.instanceAxios.post('/truck/certificates_truck_history', data)
      .then(response => response ? response.data : null)
  }

  /**
 * Delete file in s3
 */
  static deleteTruckCertificate = (coverPhoto) => {
    return RestClientObj.instanceAxios.post('/truck/delete_certificate', coverPhoto)
      .then(response => response ? response.data : null)
  }

  /**
 * Get certificate info
 */
  static getCertificateById = (id) => {
    return RestClientObj.instanceAxios.post('/truck/get_certificate_by_id', id)
      .then(response => response ? response.data : null)
  }


  static editCertificate = (values) => {
    return RestClientObj.instanceAxios.post('/truck/edit_certificate', values)
      .then(response => response ? response.data : null)
  }


  /**
   * Lista de camiones disponibles
   * @param {*} request
   * @param {number} id_company
   * @param {string} appoiment_date // date + time
   * @param {number} page
   * @param {number} page_size
 */
  static getTrucksAvailable = (params) => {
    return RestClientObj.instanceAxios.post('/truck/get_trucks_available', params)
      .then(response => response ? response.data : null)
      .catch(error => error.response)
  }


  static getTrucksForGps = (params) => {
    return RestClientObj.instanceAxios.post('/truck/get_trucks_for_gps', params)
      .then(response => response ? response.data : null)
      .catch(error => error.response)
  }

  /**
     * Devuelve el ultimo disco
     *
   */
  static getLastDiskByCompany = (params) => {
    return RestClientObj.instanceAxios.post('/truck/last_disk', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Lista de camiones disponibles
  * @param {number} id_reservation
  * @param {number} id_truck
  */
  static analyzeTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/analyze_truck_reservation', params)
      .then(response => response ? response.data : null)
      .catch(error => error.response)
  }

  /** Guardar nuevo camion
      *
      * @param {*} request
      * @param {integer} request.brand_truck id de marca de camion
      * @param {string} request.model_truck modelo de camion
      * @param {string} request.color_truck color de camion
      * @param {integer} request.axes_truck id de eje de camion
      * @param {string} request.license_truck placa de camion
      * @param {string} request.factory_year_truck año de fabricacion de camion
      * @param {string} request.purchase_year_truck año de compra de camion
      */
  static saveTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/add_truck', params)
      .then(response => response ? response.data : null)
  }



  /**************************    LISTAR COMBUSTIBLES      **************************/
  static getFuelTypes = () => {
    return RestClientObj.instanceAxios.get('/truck/fuel_types')
      .then(response => response ? response.data : null)
  }


  /**************************    LISTA CARROCERIAS      **************************/
  static getCarrocerias = () => {
    return RestClientObj.instanceAxios.get('/truck/lista_carrocerias')
      .then(response => response ? response.data : null)
  }

  /**************************    LISTA TIPOS DE CABINAS      **************************/
  static getCabins = () => {
    return RestClientObj.instanceAxios.get('/truck/lista_cabinas')
      .then(response => response ? response.data : null)
  }

  /** Editar camion
      *
      * @param {string} request.color_truck color de camion
      * @param {integer} request.axes_truck id de eje de camion
      * @param {string} request.license_truck placa de camion
      * @param {string} request.factory_year_truck año de fabricacion de camion
      * @param {string} request.purchase_year_truck año de compra de camion
      */
  static editTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/edit_truck', params)
      .then(response => response ? response.data : null)
  }

  /** Actualizar documentos de camión
      *
      * @param {string} request.color_truck color de camion
      * @param {integer} request.axes_truck id de eje de camion
      * @param {string} request.license_truck placa de camion
      * @param {string} request.factory_year_truck año de fabricacion de camion
      * @param {string} request.purchase_year_truck año de compra de camion
      */
  static updateDocumentsTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/update_documents_truck', params)
      .then(response => response ? response.data : null)
  }


  /**
 * Búsqueda de camiones
* @param {number} params.id_truck
* @param {String} params.license placa de camion
* @param {number} params.company_id Company of truck
* @param {number} params.page
* @param {number} params.page_size
 */
  static getCamiones = params => {
    return RestClientObj.instanceAxios.post('/truck/get_trucks', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Búsqueda de camiones
* @param {number} params.id_truck
* @param {String} params.license placa de camion
* @param {number} params.company_id Company of truck
* @param {number} params.page
* @param {number} params.page_size
 */
  static getCamionesPizarra = params => {
    return RestClientObj.instanceAxios.post('/truck/get_trucks_pizarra', params)
      .then(response => response ? response.data : null)
  }


  /**
 * Búsqueda de camion por ID
 * @param {number} id_truck
 */
  static getCamion = params => {
    return RestClientObj.instanceAxios.post('/truck/get_truck', params)
      .then(response => response ? response.data : null)
  }



  /**
 * Elimina un camión
 * @param {number} id_truck
 */
  static deleteTruck = (params) => {
    return RestClientObj.instanceAxios.post('/truck/delete_truck', params)
      .then(response => response ? response.data : null)
  }


  /***********EQUIPOS************* */

  static getAtrValues = (data) => {
    return RestClientObj.instanceAxios.post('/equipments/get_atr_values', data)
      .then(response => response ? response.data : null)
  }

  static getCertificateTypes = () => {
    return RestClientObj.instanceAxios.get('/equipments/get_certificate_types')
      .then(response => response ? response.data : null)
  }


  static getChasisTypes = () => {
    return RestClientObj.instanceAxios.get('/equipments/get_chasis_types')
      .then(response => response ? response.data : null)
  }

  static getEquipos = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/get_equipments', params)
      .then(response => response ? response.data : null)
  }

  static getGeneradores = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/get_generators', params)
      .then(response => response ? response.data : null)
  }

  static getChasis = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/get_chasis', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Crea un nuevo chasis
   * @param {*} params
   * @param {number} params.id_company Only to ADMIN-MASTER | REQUIRED
   * @param {number} params.disk Identificator of equipment | REQUIRED
   * --- ATTRIBUTES ---
   * @param {number} params.tipo_cha | type - CODE: 'TIPO_CHA' , TYPE: 'SELECT' | REQUIRED
   * @param {number} params.peso_max_cha | galon 1 - CODE: 'PESO_MAX_CHA' , TYPE: 'NUMBER'
   * --- CERTIFICATE RESOURCES ---
   * @param {Array<Certificate>} params.certificates
   *
   * @typedef Certificate
   * @property {number} certificate_type_id
   * @property {string} number
   * @property {string} emitted | Format: 'YYYY-MM-DD'
   * @property {string} expired | Format: 'YYYY-MM-DD'
   * @property {Array<FileObject>} resources
   *
   * @typedef FileObject
   * @property {string} name
   * @property {string} url
   * @property {string} type | File type: 'image', 'pdf'
   */
  static saveChasis = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/add_chassis', params)
      .then(response => response ? response.data : null)
  }


  /**
  * Editar un equipo
  * @param {*} params
  * @param {number} params.id_equipment REQUIRED
  * @param {number} params.id_company Only to ADMIN-MASTER | REQUIRED
  * @param {number} params.disk Identificator of equipment
  * @param {boolean} params.is_own
    */

  static updateEquiment = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/update_equipment', params)
      .then(response => response ? response.data : null)
  }


  static deleteEquipo = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/delete_equipment', params)
      .then(response => response ? response.data : null)
  }

  static addGenerator = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/add_generator', params)
      .then(response => response ? response.data : null)
  }

  static addModem = (params) => {
    return RestClientObj.instanceAxios.post('/equipments/add_modem', params)
      .then(response => response ? response.data : null)
  }


  // static addChasis = (params) => {
  //   return RestClientObj.instanceAxios.post('/equipments/add_chasis', params)
  //     .then(response => response ? response.data : null)
  // }



  /**************************  TARIFAS Y SERVICIOS **************************/
  /** Guardar nuevo servicio
    *
    * @param {*} request
    * @param {string} request.name nombre de servicio
    * @param {string} request.code codigo sap propio
    * @param {string} request.code_particular codigo sap particular
    * @param {string} request.description descripcion de servicio
    * @param {string} request.cost costo de servicio
    * @param {boolean} request.cost_service_visible indica si el costo de servicio sera visible
    * @param {boolean} request.mandatory_service indica si el servicio sera obligatorio al crear un tarifa
    */
  static saveService = (params) => {
    return RestClientObj.instanceAxios.post('/service/add_service', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los tipos de servicios
   */
  static getTypeServices = () => {
    return RestClientObj.instanceAxios.get('/service/get_type_services')
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los tipos de servicios
   */
  static getTypeIVA = () => {
    return RestClientObj.instanceAxios.get('/service/get_iva')
      .then(response => response ? response.data : null)
  }

  /** actualizar datos servicio
    *
    * @param {*} request
    * @param {number} request.id_service id de servicio
    * @param {string} request.name nombre de servicio
    * @param {string} request.code codigo de servicio
    * @param {string} request.description descripcion de servicio
    * @param {string} request.cost costo de servicio
    * @param {boolean} request.cost_service_visible indica si el costo de servicio sera visible
    * @param {boolean} request.mandatory_service indica si el servicio sera obligatorio al crear un tarifa
    */
  static updateService = (params) => {
    return RestClientObj.instanceAxios.post('/service/update_service', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los servicios
   * @param {string} params.name
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getServices = (params) => {
    return RestClientObj.instanceAxios.post('/service/get_services', params)
      .then(response => response ? response.data : null)

  }

  /**
   * Eliminar servicio
   * @param {number} params.id_service
   */
  static deleteService = (params) => {
    return RestClientObj.instanceAxios.post('/service/delete_service', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Búsqueda de servicio por ID
  * @param {number} id_service
  */
  static getServicio = id_service => {
    return RestClientObj.instanceAxios.post('/service/get_service', { id_service })
      .then(response => response ? response.data : null)
  }

  /** Guardar nueva tarifa
    *
    * @param {*} request
    * @param {object} request.port objeto de puerto (al menos un dato id_puerto o id_ciudad es requerido)
    * @param {integer} request.port.id_port id de company del puerto seleccionado
    * @param {integer} request.port.id_city id de ciudad del puerto seleccionado
    * @param {object} request.deposit objeto de deposito (al menos un dato id_deposito o id_ciudad es requerido)
    * @param {integer} request.deposit.id_deposit id de company del deposito seleccionado
    * @param {integer} request.deposit.id_city id de ciudad del deposito seleccionado
    * @param {integer} request.client_company_id id compania  del cliente (no obligatorio)
    * @param {integer} request.shipping_line_id id de linea naviera en el caso de que la tarifa sea de vacios
    * @param {integer} request.reservation_type_id id de tipo de reserva asociado a la tarifa (IMP-EXP)
    * @param {integer} request.company_id id de de compania
    * @param {String} request.fee_cost valor de la tarifa
    * @param {boolean} request.fee_vacio aplica si la tarifa es para un transporte vacio
    * @param {array<String>} request.validity_date rango de fecha de validez de una tarifa
    * @param {numeric} request.distance
    * @param {numeric} request.gallon
    * @param {numeric} request.travel_expense
    * @param {numeric} request.peaje
    * @param {object} request.plants objeto para los establecimientos entre puerto y deposito
    * @param {array<String>} request.plants.keys arrays de orden cantidad de establecimientos agregados
    * @param {array<String>} request.plants.cities array de ids de ciudades de un establecimiento
    * @param {array<String>} request.plants.locations array de ids de establecimientos
    * @param {array<service>} request.services_additional array de objetos de servicios
    */
  static saveFee = (params) => {
    return RestClientObj.instanceAxios.post('/fee/add_fee', params)
      .then(response => response ? response.data : null)
  }

  /** Guardar nueva tarifa basica, en el caso de que una reserva no encuentra una tarifa completa (caso excepcional)
   *
   * @param {*} request
   * @param {integer} request.reservation_type_id id de tipo de reserva asociado a la tarifa (IMP-EXP)
   * @param {String} request.fee_cost valor de la tarifa
   * @param {char} request.reservation_type_code codigo de tipo de reserva (Importacion: I o Exportacion: E)
   * @param {boolean} request.fee_vacio aplica si la tarifa es para un transporte vacio
   * @param {array<String>} request.validity_date rango de fecha de validez de una tarifa
   * @param {array<service>} request.services_additional array de objetos de servicios
   * @param {array<object>} request.trip_stop array de paradas. ruta de la reserva
   * @param {numeric} request.distance
   * @param {numeric} request.gallon
   * @param {numeric} request.travel_expense
   * @param {numeric} request.peaje
   */
  static saveBasicFee = (params) => {
    return RestClientObj.instanceAxios.post('/fee/add_fee_basic', params)
      .then(response => response ? response.data : null)
  }

  /** actualizar tarifa
   *
   * @param {*} request
   * @param {number} request.id_fee  id de la tarifa
   * @param {object} request.port objeto de puerto (al menos un dato id_puerto o id_ciudad es requerido)
   * @param {integer} request.port.id_port id de company del puerto seleccionado
   * @param {integer} request.port.id_city id de ciudad del puerto seleccionado
   * @param {object} request.deposit objeto de deposito (al menos un dato id_deposito o id_ciudad es requerido)
   * @param {integer} request.deposit.id_deposit id de company del deposito seleccionado
   * @param {integer} request.deposit.id_city id de ciudad del deposito seleccionado
   * @param {integer} request.client_company_id id compania  del cliente (no obligatorio)
   * @param {integer} request.shipping_line_id id de linea naviera en el caso de que la tarifa sea de vacios
   * @param {integer} request.reservation_type_id id de tipo de reserva asociado a la tarifa (IMP-EXP)
   * @param {integer} request.company_id id de de compania
   * @param {String} request.fee_cost valor de la tarifa
   * @param {boolean} request.fee_vacio aplica si la tarifa es para un transporte vacio
   * @param {array<String>} request.validity_date rango de fecha de validez de una tarifa
   * @param {numeric} request.distance
   * @param {numeric} request.gallon
   * @param {numeric} request.travel_expense
   * @param {numeric} request.peaje
   * @param {object} request.plants objeto para los establecimientos entre puerto y deposito
   * @param {array<String>} request.plants.keys arrays de orden cantidad de establecimientos agregados
   * @param {array<String>} request.plants.cities array de ids de ciudades de un establecimiento
   * @param {array<String>} request.plants.locations array de ids de establecimientos
   * @param {array<service>} request.services_additional array de objetos de servicios
   */
  static updateFee = (params) => {
    return RestClientObj.instanceAxios.post('/fee/update_fee', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtiene las tarifas
  * @param {number} params.id_company
  * @param {number} id_fee // id de tarifa
  * @param {number} open_search_port // filter port
  * @param {number} client_company_id // id compania del cliente
  * @param {number} fee_vacio // busqueda de vacio (1:Si, 0:No)
  * @param {number} reservation_type_id // id de tipo de reserva IMP-EXP
  * @param {array<number>} services // arrays de ids de servicios
  * @param {number} params.page
  * @param {number} params.page_size
  */
  static getFees = (params) => {
    return RestClientObj.instanceAxios.post('/fee/get_fees', params)
      .then(response => response ? response.data : null)

  }


  /**
  * Asigna tarifas a un grupo de reservas
  * @param {array<number>} params.reservations lista de reservas
  * @param {number} params.id_fee // id de tarifa
  */
  static updateReservasTarifas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_reservation_fee', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Búsqueda de tarifa por ID
 * @param {number} id_fee
 */
  static getTarifa = id_fee => {
    return RestClientObj.instanceAxios.post('/fee/get_fee', { id_fee })
      .then(response => response ? response.data : null)
  }

  /**
* eliminar la tarifa
* @param {number} params.id_fee
*/
  static deleteFee = (params) => {
    return RestClientObj.instanceAxios.post('/fee/delete_fee', params)
      .then(response => response ? response.data : null)

  }
  /* ************************** DESCUENTOS ************************** */

  /**
    * descuentos disponibles por cliente
    * @param {boolean} body.state
    * @param {number} body.id_discount
    * @param {boolean} body.discount_general
    * @param {string} body.reservation_type
    * @param {number} body.company_client_id
    * @param {boolean} body.available_discount
    * @param {number} body.company_transport_id
    * @param {array<number>}body.services array de ids services
*/
  static discountClientAvailable = (params) => {
    return RestClientObj.instanceAxios.post('/discount/get_discount_client_available', params)
      .then(response => response ? response.data : null)
  }

  static guardarDescuentosReservas = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/save_discount_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**
    * canjear codigo de descuento
    * @param {string} body.code_discount
  */
  static canjearCodigoDescuento = (params) => {
    return RestClientObj.instanceAxios.post('/discount/redeem_code_discount', params)
      .then(response => response ? response.data : null)
  }

  /**
  * listar mis descuentos
  * @param {number} params.page
  * @param {number} params.page_size
  */
  static myDiscounts = (params) => {
    return RestClientObj.instanceAxios.post('/discount/my_discounts', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Crea un descuento
   * @param {string} body.name_discount nombre del descuento
   * @param {string} body.code_discount codigo del descuento
   * @param {number} body.services_id id de servicio
   * @param {String} body.reservation_type tipo de reserva para descuento I-E
   * @param {number} body.quantity_discount cantidad de descuentos
   * @param {number} body.percentage_discount porcentaje de descuento (si el usuario ingresa en porcentaje caso contrario null)
   * @param {number} body.cost_discount costo de descuento (si el usuario ingresa el valor del descuento caso contrario null)
   * @param {number} body.company_client_id id compañia de cliente
   * @param {boolean} body.discount_general si el descuento es general o no
   * @param {array<number>} body.fees array de ids de tarifas (si el usuario selecciona las tarifas caso contrario arreglo vacio)
   * @param {string} body.description_discount
   * @param {number} body.number_max_reservation
   * @param {string} body.expiration_date
    */
  static saveDiscount = (params) => {
    return RestClientObj.instanceAxios.post('/discount/add_discount', params)
      .then(response => response ? response.data : null)
  }


  /** actualizar tarifa
   *
  * @param {*} request
  * @param {number} request.id_discount  id de la tarifa
  * @param {string} request.name_discount nombre del descuento
  * @param {string} request.code_discount codigo del descuento
  * @param {number} request.services_id id de servicio
  * @param {String} request.reservation_type tipo de reserva para descuento I-E
  * @param {number} request.quantity_discount cantidad de descuentos
  * @param {number} request.quantity_discount_used cantidad de descuentos usados
  * @param {number} request.percentage_discount porcentaje de descuento (si el usuario ingresa en porcentaje caso contrario null)
  * @param {number} request.cost_discount costo de descuento (si el usuario ingresa el valor del descuento caso contrario null)
  * @param {boolean} request.discount_general si el descuento es general o no
  * @param {number} request.company_client_id id compañia de cliente
  * @param {array<number>} request.fees array de ids de tarifas (si el usuario selecciona las tarifas caso contrario arreglo vacio)
  * @param {string} body.description_discount
  * @param {number} body.number_max_reservation
  * @param {string} body.expiration_date
  */
  static updateDiscount = (params) => {
    return RestClientObj.instanceAxios.post('/discount/update_discount', params)
      .then(response => response ? response.data : null)
  }


  /** delete tarifa
   *
  * @param {*} request
  * @param {number} request.id_discount  id de la tarifa
   */
  static deleteDiscount = (params) => {
    return RestClientObj.instanceAxios.post('/discount/delete_discount', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Obtiene los descuentos
  * @param {boolean} params.state
  * @param {number} params.page
  * @param {number} params.page_size
  */
  static getDiscounts = (params) => {
    return RestClientObj.instanceAxios.post('/discount/get_discounts', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Búsqueda de descuento por ID
  * @param {number} id_discount
  */
  static getDescuento = (params) => {
    return RestClientObj.instanceAxios.post('/discount/get_discount', params)
      .then(response => response ? response.data : null)

  }

  /* ************************** COSTOS ************************** */

  /**
    * Obtiene los tipos de costos dinámicoss
    */
  static getTypeCosts = () => {
    return RestClientObj.instanceAxios.get('/costs/get_costs_type', {})
      .then(response => response ? response.data : null)
  }

  static getCostosParticularesErrores = () => {
    return RestClientObj.instanceAxios.get('/costs/get_costs_providers_errors', {})
      .then(response => response ? response.data : null)
  }


  static getReembolsosErrores = () => {
    return RestClientObj.instanceAxios.get('/refunds/get_refunds_errors', {})
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los costos dinámicoss
   * @param {number} id_company
   */
  static getCostosDinamicos = (id_company) => {
    return RestClientObj.instanceAxios.post('/costs/get_dinamic_costs', { id_company })
      .then(response => response ? response.data : null)
  }

  /**
   * Get Dinamic Costs of a company.
   * @param {number} params.id_company | The ID COMPANY is got of the info user. In case of ADMIN MASTER user, he get cost of another company.
   * @param {number} params.id_cost_type
   * @param {number} params.ratio
   */
  static saveCostosDinamicos = (params) => {
    return RestClientObj.instanceAxios.post('/costs/save_dinamic_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Elimina un costo dinámico
   * @param {number} id_cost
   * @param {number} id_company
   */
  static deleteCostoDinamico = (params) => {
    return RestClientObj.instanceAxios.post('/costs/delete_dinamic_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Edita un costo dinámico
   * @param {number} id_cost
   * @param {number} id_company
   */
  static editarCostoDinamico = (params) => {
    return RestClientObj.instanceAxios.post('/costs/edit_dinamic_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los costos dinámicoss
   * @param {number} params.id_company
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getCostosDepositos = (params) => {
    return RestClientObj.instanceAxios.post('/costs/get_deposit_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los costos dinámicoss
   * @param {number} params.id_company_transport
   * @param {number} params.id_company_deposit
   * @param {number} params.value
   */
  static saveCostosDepositos = (params) => {
    return RestClientObj.instanceAxios.post('/costs/save_deposit_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los costos dinámicoss
   * @param {number} params.id_cost_deposit | In case is editing
   * @param {number} params.value
   */
  static editCostosDepositos = (params) => {
    return RestClientObj.instanceAxios.post('/costs/edit_deposit_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
     * Obtiene los costos dinámicoss
     * @param {number} params.id_cost_deposit | In case is editing
     */
  static deleteCostosDepositos = (params) => {
    return RestClientObj.instanceAxios.post('/costs/delete_deposit_costs', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene las asociaciones de un transportista con sus tarifas
   * @param {number} params.id_company
   * @param {number} params.page
   * @param {number} params.page_size
   */
  static getCompaniesAssociations = (params) => {
    return RestClientObj.instanceAxios.post('/company/get_associations', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Registrar Salida manualmente
  * @param {number} params.id_reservation_milestone_guide numero de guia
  * @param {number} params.id_reservation numero de reserva
  * @param {string} params.type_guide
  * @param {string} params.date_register fecha y hora de registro de salida
  */
  static registrarSalida = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/departure_reservation_milestone_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Registrar llegada manualmente
 * @param {number} params.id_reservation_milestone_guide numero de guia
 * @param {number} params.id_reservation numero de reserva
 * @param {string} params.type_guide
 * @param {string} params.date_register fecha y hora de registro de llegada
 */
  static registrarLlegada = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/arrival_reservation_milestone_guide', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Obtiene las asociaciones de un transportista con sus tarifas
   * @param {number} params.id_company
   */
  static saveAssociation = (params) => {
    return RestClientObj.instanceAxios.post('/company/save_association', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene las asociaciones de un transportista con sus tarifas
   * @param {number} params.id_association
   */
  static deleteAssociation = (params) => {
    return RestClientObj.instanceAxios.post('/company/delete_association', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Save a fee of sub_reserve with its respective services.
   * @param {number} id_company_association
   * @param {number} id_fee
   * @param {array<service_obj>} services
   * @typedef service_obj
   * @property {number} id_fee_service
   * @property {number} cost
   */
  static saveFeeSubReserve = (params) => {
    return RestClientObj.instanceAxios.post('/fee/save_fee_sub_reserve', params)
      .then(response => response ? response.data : null)
  }

  static cargarListaServiciosCostos = () => {
    return RestClientObj.instanceAxios.get('/costs/get_services_costs')
      .then(response => response ? response.data : null)
  }

  static actualizarRubrosSubTarifa = (params) => {
    return RestClientObj.instanceAxios.post('/fee/update_rubros_sub_reserve', params)
      .then(response => response ? response.data : null)
  }

  static actualizarRubroSubTarifa = (params) => {
    return RestClientObj.instanceAxios.put('/fee/update_cost_sub_reserve', params)
      .then(response => response ? response.data : null)
  }

  static deleteFeeSubReserveService = (params) => {
    return RestClientObj.instanceAxios.post('/fee/delete_fee_sub_reserve_service', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Elinia una sub-tarifa
   * @param {number} id_fee_sub_reserve
   */
  static deleteFeeSubReserve = (params) => {
    return RestClientObj.instanceAxios.post('/fee/delete_fee_sub_reserve', params)
      .then(response => response ? response.data : null)
  }

  static buscarTarifaVentaReserva = (params) => {
    return RestClientObj.instanceAxios.post('/fee/get_reservation_fee', params)
      .then(response => response ? response.data : null)
  }

  static buscarTarifaCompraReserva = (params) => {
    return RestClientObj.instanceAxios.post('/fee/get_reservation_fee_sub_reserve', params)
      .then(response => response ? response.data : null)
  }

  /* Servicios de Servidor de Coordenadas*/

  /**
  * Consulta trayecto recorrido por numero de reserva
  * @param {number} id_reservation numero de reserva
  * @param {Array<number>} filter.id_milestones id de paradas de hitos
  */

  static getTrayectoRecorrido = (params) => {
    return axios({
      method: 'post',
      headers: {
        'Autorization': RestClientObj.instanceAxios.defaults.headers.common.Authorization
      },
      url: `${baseURLCoordenadas}coordinate/get_trayectos_recorridos`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
   * Consulta la ultima ubicacion de un camion en una reserva
   * @param {string} company_transport_ruc ruc de compañia de transporte
   * @param {number} disk_truck numero de disco de camion asignado a la reserva
   * @param {string}  license_plate_truck numero de placa de camion asignado a la reserva
   */

  static ultimaUbicacionCamion = (params, token = null) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/last_coordinate_truck`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  static ultimaUbicacionViaje = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/last_coordinate_guide`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
   * 
   */
  static ultimaUbicacionConductor = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/last_coordinate_driver_app`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }


  /**
   * Consulta la ultima ubicacion de todos los camiones registrados
   */

  static ultimaUbicacionCamiones = (params, token = null) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/last_location_trucks`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
   * Consulta la estimacion de una hito activo de una guia
   * @param {number} id_modem id modem del vehiculo
   * @param {string} company_transport_ruc ruc de transportista
   * @param {number} id_reservation id de reserva
   * @param {Object} last_coordinate ultima coordenada de camion recibida
   * @param {float} last_coordinate.latitude
   * @param {float} last_coordinate.longitude
   */
  static estimacionesHitoActivo = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/estimate_guide_in_progress`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }


  /**
   * Consulta el recorrido que se hizo para una reserva solo con GPS
   * @param {number} id_reservation_milestone_guide id de guia
   * @param {number} id_reservation id reserva
   * @param {number} milestone_id
   */

  static recorridoCamionReserva = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/tracking_reservation`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
 * Consulta el recorrido que se hizo para una reserva por medio de app movil
 * @param {number} id_reservation_milestone_guide id de guia
 * @param {number} id_reservation id reserva
 * @param {number} milestone_id
 */

  static recorridoCamionReservaGPSAPP = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/tracking_gps_app_reservation`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /**
  * Consulta el recorrido que se hizo para una reserva confirmada
  * @param {number} id_reservation_milestone_guide id de guia
  * @param {number} id_reservation id reserva
  * @param {number} milestone_id
  */
  static recorridoCamionReservaCliente = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/tracking_client_reservation`,
      data: params
    }).then(response => response ? response.data : null);
  }

  /**
  * Consulta la ultima ubicacion de los cabezales solicitados
  * @param {array<number>} cabezales array de numeros de cabezales
  */
  static ultimaUbicacionTodosCamiones = (params) => {
    return axios({
      method: 'post',
      url: `${baseURLCoordenadas}coordinate/last_location_all_trucks`,
      data: params
    }).then(resp => resp ? resp.data : null)
  }

  /* ---------------------- ROLES Y PERMISOS ------------------------ */

  /**
   * Obtiene los permisos según el rol y tipo de compañía
   */
  static getPermisos = (id_role, id_company_type) => {
    return RestClientObj.instanceAxios.post('/permissions/get_permissions', { id_role, id_company_type })
      .then(response => response ? response.data : null)
  }


  static getPermisosCW = (params) => {
    return RestClientObj.instanceAxios.post('/permissions/get_permissions_cargo_wise', params)
      .then(response => response ? response.data : null)
  }

  /**
   *  Actualiza el permisos de un rol
   */
  static actualizarPermiso = (params = { id_role: null, id_company_type: null, type: '', allowed: null, id_permission_action: null, id_permission_url: null, id_permission_action_url: null }) => {
    return RestClientObj.instanceAxios.post('/permissions/update_permission', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Obtiene los permisos según el rol y tipo de compañía
   */
  static getPermisosPorModulos = (id_role, id_company_type) => {
    return RestClientObj.instanceAxios.post('/permissions/get_permissions_by_modules', { id_role, id_company_type })
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS obtiene conductores con sus bonos totales
   */
  static get_reporte_conductores_totales = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_conductores_bonos_totales', params)
      .then(response => response ? response.data : null)
  }


  static consulta_conductor_asignado_viaje = (params) => {
    return RestClientObj.instanceAxios.post('/reports/consulta_conductor_asignado_viaje', params)
      .then(response => response ? response.data : null)
  }

  static reenviar_notificacion_conductor = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reenviar_notificacion_conductor', params)
      .then(response => response ? response.data : null)
  }
  /**
   * Obtiene total y lista de reservas con contenedores pendientes por entregar en deposito
   */
  static get_contenedores_pendientes = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_containers_pending', params)
      .then(response => response ? response.data : null)
  }

  static get_camiones_no_operativos = () => {
    return RestClientObj.instanceAxios.get('/reports/get_trucks_no_available')
      .then(response => response ? response.data : null)
  }

  static asociarGuiaContenedor = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/asociar_guia_contenedor', params)
      .then(response => response ? response.data : null)
  }

  static reservasPorDespachar = () => {
    return RestClientObj.instanceAxios.get('/reservation/reservas_por_despachar')
      .then(response => response ? response.data : null)
  }

  /**
 * Obtiene total y lista de reservas con contenedores entregados a deposito
 */
  static get_contenedores_entregados = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_containers_returned', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Eliminar la relacion de contenedor por devolver en la guia de transporte creada
 */
  static quitarContenedorGuia = params => {
    return RestClientObj.instanceAxios.post('/reports/delete_containers_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS estado de reservas de un cliente de las guias de viaje activas
   */
  static get_status_reservas_en_curso = (params) => {
    return RestClientObj.instanceAxios.post('/reports/status_reservas_en_curso', params)
      .then(response => response ? response.data : null)
  }

  /**
   * obtener link de compartir monitoreo de reservas de destinatario
   */
  static get_link_monitoreo_destinatario = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_link_monitoreo_destinatario', params)
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS obtiene conductores con sus bonos detalles
   */
  static get_reporte_conductores_detalles = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_conductores_bonos_detalles', params)
      .then(response => response ? response.data : null)
  }

  /**
 * REPORT asesores
 */
  static get_reporte_asesores = (params) => {
    return RestClientObj.instanceAxios.post('/reports/reporte_asesores', params)
      .then(response => response ? response.data : null)
  }

  static get_reservas_asesores = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_reservas_asesores', params)
      .then(response => response ? response.data : null)
  }


  /**
 * REPORTS obtiene conductores con sus bonos detalles
 */
  static get_reporte_conductores_retiros = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_conductores_bonos_retiros', params)
      .then(response => response ? response.data : null)
  }


  /**
 * REPORTS obtiene los movimienos pendientes
 */
  static reportGetMovimientosPendientes = () => {
    return RestClientObj.instanceAxios.get('/reports/actividades_pendientes')
      .then(response => response ? response.data : null)
  }


  /**
   * REPORTS de viaticos
   */
  static get_reporte_viaticos = (params) => {
    return RestClientObj.instanceAxios.post('/reports/viaticos', params)
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS de fechas cortes bonos
   */
  static get_fechas_cortes_bonos = () => {
    return RestClientObj.instanceAxios.get('/reports/get_fechas_cortes_bonos')
      .then(response => response ? response.data : null)
  }

  /**
 * REPORTS de viaticos
 */
  static get_reporte_viaticos_particulares = (params) => {
    return RestClientObj.instanceAxios.post('/reports/viaticos_particulares', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Lista de cortes viaticos
   */
  static get_cortes_viaticos = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_viaticos', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Lista de cortes bonos
 */
  static get_cortes_bonos_totales = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_corte_bonos_totales', params)
      .then(response => response ? response.data : null)
  }

  static get_cortes_bonos_detalle = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_corte_bonos_detalles', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Lista de cortes viaticos
 */
  static get_cortes_particulares = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_particulares', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Descargar excel
   */
  static get_report_reservations = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_report_reservations', params)
      .then(response => response ? response.data : null)
  }

  static get_reservas_equipo_operativo = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_reservas_equipo_operativo', params)
      .then(response => response ? response.data : null)

  }

  /**
 * Descargar excel
 */
  static get_cortes_viaticos_totales = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_viaticos_totales', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Descargar excel
 */
  static get_cortes_particulares_totales = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_particulares_totales', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Descargar excel
 */
  static get_params_additional_guide = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/get_params_additional_guide', params)
      .then(response => response ? response.data : null)
  }

  static actualizar_anticipo_particulares = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_anticipo_particulares', params)
      .then(response => response ? response.data : null)
  }


  /**
 * Descargar excel
 */
  static get_cortes_viaticos_detalle = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_viaticos_detalle', params)
      .then(response => response ? response.data : null)
  }

  /**
* Descargar excel
*/
  static get_cortes_particulares_detalle = (params) => {
    return RestClientObj.instanceAxios.post('/reports/get_cortes_particulares_detalle', params)
      .then(response => response ? response.data : null)
  }

  static enviar_sap_particulares = (params) => {
    return RestClientObj.instanceAxios.post('/reports/enviar_sap_particulares', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Registrar corte viaticos conductor
   */
  static generar_corte_viaticos = (params) => {
    return RestClientObj.instanceAxios.post('/reports/generar_corte_viaticos', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Registrar corte bonos conductor
   */
  static generar_corte_bonos = (params) => {
    return RestClientObj.instanceAxios.post('/reports/generar_corte_bonos', params)
      .then(response => response ? response.data : null)
  }


  /**
   * Registrar corte viaticos conductor
   */
  static generar_corte_particulares = (params) => {
    return RestClientObj.instanceAxios.post('/reports/generar_corte_particulares', params)
      .then(response => response ? response.data : null)
  }

  static lista_documentos_cierre_guia = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/list_files_close_guide', params)
      .then(response => response ? response.data : null)
  }


  /**
   * REPORTS obtiene los cabezales vs actividades activos
   */
  static reportGetCabezalesActividades = (params, token = null) => {

    if (token)
      return axios({
        method: 'post',
        headers: {
          'Authorization': token
        },
        url: `${baseURL}/services_no_logging/monitoreo/cabezales_activos`,
        data: params
      }).then(resp => resp ? resp.data : null)


    return RestClientObj.instanceAxios.post('/reports/cabezales_actividades', params)
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS obtiene el viaje con GPS o APP
   */
  static getViajeGpsApp = async function (params) {
    console.log('params', params)

    const resp = RestClientObj.instanceAxios.post(`${baseURL}/reservation/update_milestone_guide_tracking`, params)
      .then(response => {
        return response ? response.data : null;
      });

    return resp;

  }

  static monitoreo_guia_transporte = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/monitoring_reservation_milestone_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
 * REPORTS obtiene los cabezales vs actividades pendientes por cerrar
 */
  static reportCabezalesPorCerrar = (params) => {
    return RestClientObj.instanceAxios.post('/reports/reservation_guide_to_close', params)
      .then(response => response ? response.data : null)
  }


  /**
 * REPORTS obtiene las reservas de los movimientos pendientes
 */
  static actividades_pendientes_reservas = (params) => {
    return RestClientObj.instanceAxios.post('/reports/actividades_pendientes_reservas', params)
      .then(response => response ? response.data : null)
  }

  /**
   * REPORTS obtiene los movimienos pendientes detalle (PIZARRA)
   */
  static reportGetMovimientosPendientesDetalle = () => {
    return RestClientObj.instanceAxios.get('/reports/actividades_pendientes_detalle')
      .then(response => response ? response.data : null)
  }

  /**
   * Actualizar camion en guia
   */
  static updateTruckGuide = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_truck_guide', params)
      .then(response => response ? response.data : null)
  }

  /**
  * Delete document from
  */
  static deleteDocument = (document) => {
    return RestClientObj.instanceAxios.post('/reservation/delete_document', document)
      .then(response => response ? response.data : null)
  }

  static instanceCoorsAxios = axios.create({
    baseURL: baseURLCoordenadas,
  })

  /**
   * Obtiene un historico de coordenadas de los camiones en un rango de fechas
   * /**
   * Consulta el recorrido de un camion en en un rango de fechas
   * @param {number} truck_id id del camion
   * @param {string} start_date fecha inicial del rango
   * @param {number} end_date fecha final del rango
   */

  static getMonitoringRangeDate = (params) => {
    return RestClientObj.instanceCoorsAxios.post('/coordinate/get_monitoring_data', params)
      .then(response => response ? response.data : null)
  }

  static deleteMonitoringRecord = (params) => {
    return RestClientObj.instanceCoorsAxios.post('/coordinate/disable_monitoring_record', params)
      .then(response => response ? response.data : null)
  }


  /* ---------------------- PROYECCIONES ------------------------ */
  static getEstadisticas = () => {
    return RestClientObj.instanceAxios.get('/proyecciones/getEstadisticas')
      .then(response => response ? response.data : null)
  }

  static getTablaAnual = () => {
    return RestClientObj.instanceAxios.get('/proyecciones/getTablaAnual')
      .then(response => response ? response.data : null)
  }

  static getProyeccionesClientes = () => {
    return RestClientObj.instanceAxios.get('/proyecciones/getProyeccionesClientes')
      .then(response => response ? response.data : null)
  }

  static getProyeccionesDestinatarios = () => {
    return RestClientObj.instanceAxios.get('/proyecciones/getProyeccionesDestinatarios')
      .then(response => response ? response.data : null)
  }

  static guardarParametrosCostosPropios = (params) => {
    return RestClientObj.instanceAxios.post('/parametros/save_parametros_costos_propios', params)
      .then(response => response ? response.data : null)
  }

  static getParametrosCostosPropios = () => {
    return RestClientObj.instanceAxios.get('/parametros/get_parametros_costos_propios')
      .then(response => response ? response.data : null)
  }

  static guardarParamentrosGenerales = (params) => {
    return RestClientObj.instanceAxios.post('/parametros/save_parametros_generales', params)
      .then(response => response ? response.data : null)
  }

  static getParametrosGenerales = () => {
    return RestClientObj.instanceAxios.get('/parametros/get_parametros_generales')
      .then(response => response ? response.data : null)
  }

  static updateFirebaseToken = (params) => {
    return RestClientObj.instanceAxios.post('/users/update_firebase_token', params)
      .then(response => response ? response.data : null)
  }

  static registrarGuiaFalsoFlete = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/update_guide_false_flete', params)
      .then(response => response ? response.data : null)
  }

  static eliminarPasosReserva = (params) => {
    return RestClientObj.instanceAxios.post('/reservation/reset_template_reservation', params)
      .then(response => response ? response.data : null)
  }

  /**Integracion NAVEC */
  static actualizarEmbarqueNEC = (params) => {
    return RestClientObj.instanceAxios.post('/nav_ec/actualizar_embarque_nec', params)
      .then(response => response ? response.data : null)
  }


  /* ---------------------- VACIOS ------------------------ */
  static getListadoNavieras = () => {
    return RestClientObj.instanceAxios.get('/vacios/obtener_listado_nav')
      .then(response => response ? response.data : null)
  }

  static getListadoOrigenDestinos = () => {
    return RestClientObj.instanceAxios.get('/vacios/obtener_origen_destino')
      .then(response => response ? response.data : null)
  }

  static getListadoBuques = () => {
    return RestClientObj.instanceAxios.get('/vacios/obtener_buques')
      .then(response => response ? response.data : null)
  }

  static getListadoUsuarios = (params) => {
    return RestClientObj.instanceAxios.post('/vacios/obtener_usuarios_vacios', params)
      .then(response => response ? response.data : null)
  }

  static getListadoMovimientosVacio = () => {
    return RestClientObj.instanceAxios.get('/vacios/obtener_movimientos')
      .then(response => response ? response.data : null)
  }

  static postOperacionMovientoVacio = (params) => {
    return RestClientObj.instanceAxios.post('/vacios/cargar_contenedores', params)
      .then(response => response ? response.data : null)
  }

  static putActualizarListadoVacios = (id, params) => {
    return RestClientObj.instanceAxios.put(`/vacios/actualizar_listado/${id}`, params)
      .then(response => response ? response.data : null)
  }

  static deleteOperacionMovVacio = (id) => {
    return RestClientObj.instanceAxios.delete(`/vacios/cancelar_op_vacios/${id}`)
      .then(response => response ? response.data : null)
  }

  static putSincronizarBDVacios = (id) => {
    return RestClientObj.instanceAxios.put(`/vacios/sincronizar_BDVacio/${id}`)
      .then(response => response ? response.data : null)
  }

  /* ---------------------- DISV ------------------------ */
  static createDisvDraft = (params) => {
    return RestClientObj.instanceAxios.post('/disv/create_disv_draft', params)
      .then(response => response ? response.data : null)
  }

  static deleteCreateDisvDraft = (params) => {
    return RestClientObj.instanceAxios.post('/disv/create_disv_draft', params)
      .then(response => response ? response.data : null)
  }

  static updateDisvDraft = (params) => {
    return RestClientObj.instanceAxios.post('/disv/update_disv_draft', params)
      .then(response => response ? response.data : null)
  }

  static validateDisv = (params) => {
    return RestClientObj.instanceAxios.post('/disv/validate_disv', params)
      .then(response => response ? response.data : null)
  }

  static clientesTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/list_client_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static listaNavierasTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/list_shipping_lines_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static listaNavierasDPWIntegracion = () => {
    return RestClientObj.instanceAxios.get('/dpw/list_shipping_lines_dpw_integracion')
      .then(response => response ? response.data : null)
  }


  static tiposContenedoresDPWIntegracion = () => {
    return RestClientObj.instanceAxios.get('/dpw/list_type_container_dpw_integracion')
      .then(response => response ? response.data : null)
  }

  static productosTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/products_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static guardarLineaNavieraTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_shipping_lines_tpg_integracion', params)
      .then(response => response ? response.data : null)
  }


  static guardarLineaNavieraDPWIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/dpw/save_shipping_lines_dpw_integracion', params)
      .then(response => response ? response.data : null)
  }

  static guardarTipoContenedorDPWIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/dpw/save_container_type_dpw_integracion', params)
      .then(response => response ? response.data : null)
  }

  static depositosTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/list_deposit_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static guardarClienteTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_client_tpg_integracion', params)
      .then(response => response ? response.data : null)
  }

  static guardarDepositoTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_deposit_tpg_integracion', params)
      .then(response => response ? response.data : null)
  }

  static guardarProductoTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_product_tpg_integracion', params)
      .then(response => response ? response.data : null)
  }

  static provinciaTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/list_province_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static guardarProvinciaTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_province_tpg_integracion', params)
      .then(response => response ? response.data : null)
  }

  static ciudadTPGIntegracion = () => {
    return RestClientObj.instanceAxios.get('/disv/list_canton_tpg_integracion')
      .then(response => response ? response.data : null)
  }

  static guardarCiudadTPGIntegracion = (params) => {
    return RestClientObj.instanceAxios.post('/disv/save_canton_tpg_integracion', params)
      .then(response => response ? response.data : null)
    /*-------------- Reservation Observation Comments ----------------*/
  }

  static getReservationComments(params) {
    return RestClientObj.instanceAxios
      .get(`/reservation/reservation-comments/${params.reservation_id}`)
      .then((response) => (response ? response.data : null));
  }

  static addReservationComment(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/add-reservation-comment", params)
      .then((response) => (response ? response.data : null));
  }

  static updateReservationAsesor(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/update-reservation-asesor", params)
      .then((response) => (response ? response.data : null));
  }


  /*-------------- Metodos de logs ----------------*/

  /**
   * 
   * @param {object} filters 
   * @param {integer} page 
   * @param {integer} max 
   * @returns 
   */
  static getQrLogs(filters = {}) {
    return RestClientObj.instanceAxios
      .get(`/qr_log/mostrar`, { params: filters })
      .then((response) => (response ? response.data : null));
  }

  /*------------------------*/

  static getUsersAsesors() {
    return RestClientObj.instanceAxios
      .get(`/users/users_asesores`)
      .then((response) => response ? response.data : null)
  }

  static getUsersNotAsesors() {
    return RestClientObj.instanceAxios
      .get('/users/users_not_asesores')
      .then((response) => (response ? response.data : null));
  }

  static updateUserAsesor(params) {
    return RestClientObj.instanceAxios
      .post('/users/udpate_user_asesor', params)
      .then((response) => (response ? response.data : null));
  }

  static updateReservationOperativo(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/update-reservation-operativo", params)
      .then((response) => (response ? response.data : null));
  }

  static addEquipoOperativo(params) {
    return RestClientObj.instanceAxios
      .post("/company/add_equipo_operativo", params)
      .then((response) => (response ? response.data : null));
  }

  static deleteEquipoOperativo(params) {
    return RestClientObj.instanceAxios
      .post("/company/delete_equipo_operativo", params)
      .then((response) => (response ? response.data : null));
  }

  static getUsersTranspoint() {
    return RestClientObj.instanceAxios
      .post('/users/get_users_transpoint')
      .then((response) => (response ? response.data : null));
  }

  static updateEquipoOperativo(params) {
    return RestClientObj.instanceAxios
      .post('/company/update_equipo_operativo', params)
      .then((response) => (response ? response.data : null));
  }

  static getReservationServices(params) {
    return RestClientObj.instanceAxios
      .get('/reservation/get-services')
      .then((response) => (response ? response.data : null));
  }

  static addResevationServices(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/add_reservation_services", params)
      .then((response) => (response ? response.data : null));
  }

  static lista_rubros_costos_creacion_aprobacion_reservas() {
    return RestClientObj.instanceAxios
      .get("/reservation/lista_rubros_costos_creacion_aprobacion_reservas")
      .then((response) => (response ? response.data : null));
  } s

  static buscarTarifaVenta(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/find_reservation_fee", params)
      .then((response) => (response ? response.data : null));
  }

  static getSubfeesForReservation(params) {
    return RestClientObj.instanceAxios
      .post("/fee/get_sub_fees_reservation", params)
      .then((response) => (response ? response.data : null));
  }

  static addShippingLineToDeposit(params) {
    return RestClientObj.instanceAxios
      .post("/company/add_shipping_line_deposit", params)
      .then((response) => (response.data ? response.data : null));
  }

  static getDepositShippingLines(deposit) {
    return RestClientObj.instanceAxios
      .get(`/company/get_shipping_lines_by_deposit/${deposit}`)
      .then((response) => (response.data ? response.data : null));
  }

  static removeShippingLineDeposit(params) {
    return RestClientObj.instanceAxios
      .post(`/company/remove_shipping_line_deposit`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static updateShippingLineCost(params) {
    return RestClientObj.instanceAxios
      .post(`/company/update_shipping_line_cost`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static getShippingLineCost(params) {
    return RestClientObj.instanceAxios
      .post(`/company/get_shipping_line_cost`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static saveAlquilerGeneradorCost(params) {
    return RestClientObj.instanceAxios
      .post(`/company/save_alquiler_generador_cost`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static getAlquilerGeneradorCost(id_company) {
    return RestClientObj.instanceAxios
      .get(`/company/get_alquiler_generador_cost/${id_company}`)
      .then((response) => (response.data ? response.data : null));
  }

  static updateReservationServices(params) {
    return RestClientObj.instanceAxios
      .post("/reservation/update_services", params)
      .then((response) => (response.data ? response.data : null));
  }
  /**
 * Send notification to the driver to open the app
 * @param {number} id_driver
 */
  static sendDriverNotification(id_driver) {
    return RestClientObj.instanceAxios.post('/reservation/send_driver_notification', { id_driver })
      .then(response => response ? response.data : null)
  }

  static getServicesByReservation(id_reservation) {
    return RestClientObj.instanceAxios
      .get(`/reservation/get_reservation_services/${id_reservation}`)
      .then((response) => (response.data ? response.data : null));
  }

  static getReservasTransportistasParticular(params) {
    return RestClientObj.instanceAxios
      .post(`/transportistas/list_reservations_carrier`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static getReservasTransportistasParticularReporte(params) {
    return RestClientObj.instanceAxios
      .post(`/transportistas/report_reservations_carrier`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static getReservasConductoresParticular(params) {
    return RestClientObj.instanceAxios
      .post(`/conductores/list_reservations_carrier`, params)
      .then((response) => (response.data ? response.data : null));
  }

  static getReservasConductoresParticularReporte(params) {
    return RestClientObj.instanceAxios
      .post(`/conductores/report_reservations_carrier`, params)
      .then((response) => (response.data ? response.data : null));
  }

  /**********************************************
   *                                            *
   *                PROCUREMENT                 *
   *                                            *
   **********************************************/


  /**
   * Búsqueda de conductores
 * @param {number} params.id_driver
 * @param {number} params.name Driver's Name or Lastname
 * @param {number} params.company_id Company of Driver
 * @param {number} params.page
 * @param {number} params.page_size
   */
  static getConductoresProcurement = params => {
    return RestClientObj.instanceAxios.post('/procurement/driver/get_drivers', params)
      .then(response => response ? response.data : null)
  }

  static getAllConductoresProcurement = () => {
    return RestClientObj.instanceAxios.get('/procurement/driver/get_all_drivers')
      .then(response => response ? response.data : null)
  }

  static getConductorProcurement = params => {
    return RestClientObj.instanceAxios.post('/procurement/driver/get_driver', params)
      .then(response => response ? response.data : null)
  }

  static actualizarPuntosLicenciaConductor = (params) => {
    return RestClientObj.instanceAxios.put('/procurement/driver/edit_license_points', params)
      .then(response => response ? response.data : null)
      .catch(error => {
        console.error("Error actualizando los puntos de licencia", error);
        throw error; // O manejar el error de la forma que prefieras
      });
  }



  /**
* Búsqueda de camion por ID
* @param {number} id_truck
*/
  static getCamionProcurement = params => {
    return RestClientObj.instanceAxios.post('/procurement/truck/get_truck', params)
      .then(response => response ? response.data : null)
  }

  static getCamionesProcurement = params => {
    return RestClientObj.instanceAxios.post('/procurement/truck/get_trucks', params)
      .then(response => response ? response.data : null)
  }

  static getAxesTruckProcurement = () => {
    return RestClientObj.instanceAxios.get('/procurement/truck/axes_truck')
      .then(response => response ? response.data : null)
  }

  static getEstadosCamionProcurement = () => {
    return RestClientObj.instanceAxios.get('/procurement/truck/get_status_truck')
      .then(response => response ? response.data : null)
  }

  static getTruckCertificatesProcurement = (data) => {
    return RestClientObj.instanceAxios.post('/procurement/truck/certificates_truck_history', data)
      .then(response => response ? response.data : null)
  }

  static getAllCamionesProcurement = () => {
    return RestClientObj.instanceAxios.get('/procurement/truck/get_all_trucks')
      .then(response => response ? response.data : null)
  }



  static getEmpresaProcurement = id_company => {
    return RestClientObj.instanceAxios.post('/procurement/company/get_company', { id_company })
      .then(response => response ? response.data : null)
  }

  static getEstablecimientosProcurement = (params) => {
    return RestClientObj.instanceAxios.post('/procurement/location/get_locations', params)
      .then(response => response ? response.data : null)
  }

  /**
   * Búsqueda de conductor por ID
   */
  static getLineasNavierasProcurement = () => {
    return RestClientObj.instanceAxios.get('/procurement/company/get_shipping_lines')
      .then(response => response ? response.data : null)
  }

  static getDepositShippingLinesProcurement(deposit) {
    return RestClientObj.instanceAxios
      .get(`/procurement/company/get_shipping_lines_by_deposit/${deposit}`)
      .then((response) => (response.data ? response.data : null));
  }

  static getAlquilerGeneradorCostProcurement(id_company) {
    return RestClientObj.instanceAxios
      .get(`/procurement/company/get_alquiler_generador_cost/${id_company}`)
      .then((response) => (response.data ? response.data : null));
  }

  static getTipoEmpresasProcurement = params => {
    return RestClientObj.instanceAxios.get('/procurement/company/company_type')
      .then(response => response ? response.data : null)
  }

  /**
  * Get tipo de establecimientos
  * @param {Array<string>} body.codes | code location types: 'PLT' 'PAR' 'DEP' 'PRT'
  */
  static getTipoEstablecimientoProcurement = (params) => {
    return RestClientObj.instanceAxios.post('/procurement/location/get_location_type', params)
      .then(response => response ? response.data : null)
  }

  /**
 * Búsqueda de conductores
 * @param {string} params.ruc
 * @param {string} params.id_company
 * @param {Array<string>} params.company_types | Filter by code | 'TRANS','ADM','IMP','EXP','CLT','DEP','PRT'
 * @param {string} params.business_name
 * @param {numbre} params.page_size
 * @param {numbre} params.page
 */
  static getEmpresasProcurement = params => {
    return RestClientObj.instanceAxios.post('/procurement/company/get_companies', params)
      .then(response => response ? response.data : null)
  }

  /**
   * 
   * Actualizar estado del conductor
   * 
   */

  static actualizar_estado_conductor = (params) => {
    return RestClientObj.instanceAxios.post('/driver/toggle_driver_state ', params)
      .then(response => response ? response.data : null)
  }

  /** 
   * 
   * Params Conductor 
   *
   * */
  static getParametrosConductor = () => {
    return RestClientObj.instanceAxios.get('/parametros/get_params_conductores')
      .then(response => response ? response.data : null)
  }

  static guardarParametrosConductor = (params) => {
    return RestClientObj.instanceAxios.post('/parametros/save_params_conductores', params)
      .then(response => response ? response.data : null)
  }


  // Registro de puntos de licencia

  static registroPuntosLicencia = (id_driver) => {
    return RestClientObj.instanceAxios.get('/logs/driver_state_logs', {
      params: { id_driver }
    })
      .then(response => response ? response.data : null);
  }

  // Registro cambios estado

  static registrosCambioEstadoCamion = (id_truck) => {
    return RestClientObj.instanceAxios.get('/logs/truck_state_logs', {
      params: { id_truck }
    })
      .then(response => response ? response.data : null);
  }





}
